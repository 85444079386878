import * as React from "react";
import { Box, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useState } from "react";
import PopUp from "../../../modal/PopUp";
import EditContractor from "./EditContractor";

const gray = blueGrey[900];
const grayLight = blueGrey[400];
const buttonSX = {
    width: '100%',
    fontSize: '11px',
    color: grayLight,
    display: 'flex',
    justifyContent: 'flex-start',
    height: '20px',
    "&:hover": {
        backgroundColor: 'inherit',
        color: gray
    },
}

const ActionEditContractor = (props) => {

    const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);


    return (
        <PopUp sx={buttonSX}
            name={"Редактировать"}
            container={
                <EditContractor
                    clientsData={props.clientsData}
                    handleClose={handleCloseAddClient}
                    name={props.name}
                    id={props.rowId}
                />
            }
            open={openAddClient}
            handleOpen={handleOpenAddClient}
            handleClose={handleCloseAddClient}
            width={1200}
        />
    )

}
export default ActionEditContractor;