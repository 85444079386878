import React from "react";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import loginLogo from '../../assets/img/loginLogo.svg';
import { Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { blueGrey } from "@mui/material/colors";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const gridStyles = {
    width: '100%',
    height: '100vh',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
};

const boxStyles = {
    width: '320px',
};

const boxImg = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 32px 0px'
};

const boxMessage = {
    padding: '16px 0px 16px 0px'
};

const boxSX = {
    background: gray,
    width: '100%',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const inputStyles = {
    margin: '10px 0px 10px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const LoginRestore = (props) => {

    const navigate = useNavigate();
    const [alertMessage, setAllertMessage] = useState();

    const alertMessagehandler = (value) => {
        setAllertMessage(value)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllertMessage('');
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [alertMessage]);

    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState('');

    const setMailHandler = (event) => {
        setMail((prev) => {
            prev = event.target.value;
            const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
                setMailError(() => {
                    setAllertMessage(alertErrorMail)
                })
            } else
                setMailError(() => {
                    setAllertMessage('')
                })
            return prev;
        });
    };

    const alertErrorMail = <Alert severity="error">Неверный адрес email </Alert>;
    const sendMailAlertSuccess = <Alert severity="success">Письмо отправлено на почту {mail}</Alert>;
    const alertNotFoundMail = <Alert severity="error">Не найден адрес email </Alert>;


    const cleanValues = (email) => {
        email('');
    }

    const onClickSendButtonHandler = () => {    // push mail from back
        try {
            axios.post(`https://crm.weexpert.io/api/user/restorepassword.php`, {
                email: mail
            },).then((response) => {
                console.log(response.data)
                if (response.data.success === true) {
                    cleanValues(setMail);
                    navigate('/restore/newpass');
                }
                else {
                    alertMessagehandler(alertNotFoundMail);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <AppBarForLogin />
            <Grid sx={gridStyles}>
                <Box
                    component="form"
                    sx={boxStyles}
                    noValidate
                    autoComplete="off"
                    method="post"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <Box sx={boxImg}><img sx={{ width: '100%', display: 'block', }} src={loginLogo} alt="logo"></img></Box>
                    <Box sx={{ fontSize: 20, textAlign: 'center' }}>Восстановление пароля</Box>
                    <Box sx={boxMessage}>{alertMessage}</Box>
                    <TextField sx={inputStyles}
                        fullWidth
                        label="Email"
                        variant="outlined"
                        type="email"
                        value={mail}
                        onChange={setMailHandler}
                    />
                    <Button sx={boxSX} variant="contained" onClick={() => {
                        onClickSendButtonHandler();
                    }}>Всстановить</Button>
                </Box>
            </Grid>
        </>
    );
}
