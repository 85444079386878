import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionClientDetailsEdit } from "../../components/admin-page-components/admin-page-shells/OptionClientDetailsEdit";


const ClientDetailsEdit = (props) => {
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            handleLogout={props.handleLogout}
            login={props.login} container={
                <OptionClientDetailsEdit
                    clientsData={props.clientsData}
                    selectedClientId={props.selectedClientId}
                    assistansData={props.assistansData}
                />} />
    )

}
export default ClientDetailsEdit;