import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Box, Typography, Button } from "@mui/material";
import { blueGrey, green } from "@mui/material/colors";
import ActionWithUsers from '../administration-tables-controls-components/ActionWithUsers';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


const activeGreen = green[500];
const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];

const columns = [
  {
    id: 'name',
    label: 'Полное имя',
    minWidth: 100
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100
  },
  {
    id: 'tel',
    label: 'Номер телефона',
    minWidth: 140,
    align: 'left',

  },
  {
    id: 'rights',
    label: 'Роль',
    minWidth: 100,
    align: 'left',

  },
  {
    id: 'status',
    label: 'Статус',
    minWidth: 140,
    align: 'left',
  },
  {
    id: 'dateOfCreation',
    label: 'Добавлен',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'clients',
    label: 'Клиенты',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Действия',
    minWidth: 220,
    align: 'left',
  },
];

function createData(name, email, tel, rights, status, dateOfCreation, clients, actions) {

  return { name, email, tel, rights, status, dateOfCreation, clients, actions };
}

const boxSxActive = {
  width: '110px',
  textAlign: 'center',
  padding: '10px',
  borderRadius: '16px',
  background: activeGreen,
  color: '#ffff'
};
const boxSxInActive = {
  width: '110px',
  textAlign: 'center',
  padding: '10px',
  borderRadius: '16px',
  background: grayGhost,
  color: gray
};
const boxSX = {
  background: gray,
  width: '250px',
  height: '42px',
  "&:hover": {
    border: "1px solid #000",
    color: '#ffff',
    backgroundColor: grayLight
  },
};

const statusActive = (<Box sx={boxSxActive}>Активный</Box>)
const statusInactive = (<Box sx={boxSxInActive}>Неактивный</Box>)

function OptionAdministration(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selectedRow, setSelectedRow] = useState(''); //grab client data
  const handleGrabClientData = (value) => {   //функция получения данных row
    setSelectedRow(value);
   
  };

  const administrationDataItem = props.administrationData().map((item) => (
    
    createData(
      item.name,
      item.email,
      item.tel,
      item.rights,
      (item.status === 'active' ? statusActive : statusInactive),
      item.dateOfCreation,
      item.clients.map((item, index) =>(
        <Box key={uuidv4()} sx={{ display: 'block' }}>{item}</Box>)),
      /* action component */
      <ActionWithUsers key={uuidv4()}
        name={ item.name}
        rights={item.rights}
        userData={props.userData}
        clientsData={props.clientsData}
        selectedRow={selectedRow} />, //передаю ниже стейт
        { key: uuidv4() }
    )
  ));


  return (
    <>
      <Box sx={{
        maxWidth: '1400px', margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0px 30px 0px'
      }}>
        <Typography variant='h4'>Администрация</Typography> <Link to='/admin/create/user'><Button sx={boxSX} variant="contained">+   ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ</Button> </Link>
      </Box>
      <Paper sx={{
        maxWidth: '1400px', margin: '0 auto'
      }}>
        <TableContainer sx={{ maxHeight: 660 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                  key={uuidv4()}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}

                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {administrationDataItem
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={uuidv4()}
                      align={'left'}
                      //onClick={() => handleGrabClientData(row)}  - вот так хочу получить данные из row - если раскоментить то перекроет открытие попапа
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={uuidv4()} sx={{ fontSize: '12px' }} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props.administrationData().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
export default OptionAdministration;