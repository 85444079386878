import * as React from 'react';
import { Box, TextField, Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { blueGrey, orange, grey } from "@mui/material/colors";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import PopUp from '../../modal/PopUp';
import ActionReportVat from '../admin-modal-components/ActionReportVat';
const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];
const orangeInProcess = orange[400];
const light = grey[100];

function createData(action, client, numberOfDocument, invoiceType, agent, status, vat, currency, sum, dateOfCreation, dateOfSale, billed, update) {
  return {
    action, client, numberOfDocument, invoiceType, agent, status, vat, currency, sum, dateOfCreation, dateOfSale, billed, update

  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': {}, background: `${open ? light : '#fff'}` }}>
        <TableCell sx={{ width: '20px', padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ padding: "10px 0px 0px 0px", minWidth: '20px' }} component="th" scope="row">{row.action}</TableCell>
        <TableCell sx={{ padding: "10px 0px 0px 0px", minWidth: '100px' }} align="right">{row.client}</TableCell>
        <TableCell sx={{ padding: "10px 0px 0px 0px", minWidth: '100px' }} align="right">{row.numberOfDocument}</TableCell>
        <TableCell sx={{ padding: "10px 0px 0px 0px", minWidth: '100px' }} align="right">{row.invoiceType}</TableCell>
        <TableCell sx={{ padding: "10px 0px 0px 0px", minWidth: '100px' }} align="right">{row.agent}</TableCell>
        <TableCell sx={{ padding: "10px 10px 0px 0px", minWidth: '100px' }} align="right">{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" gutterBottom component="div">
                Подробнее
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align='right'>Валюта</TableCell>
                    <TableCell align='right'>Ставка VAT</TableCell>
                    <TableCell align='right'>Сумма</TableCell>
                    <TableCell align='right'>Дата выставления</TableCell>
                    <TableCell align='right'>Дата продажи</TableCell>
                    <TableCell align='right'>Выставлена</TableCell>
                    <TableCell align='right'>Обновление</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align='right'>{row.currency}</TableCell>
                    <TableCell align='right'>{row.vat}</TableCell>
                    <TableCell align='right'>{row.sum}</TableCell>
                    <TableCell align='right'>{row.dateOfCreation}</TableCell>
                    <TableCell align='right'>{row.dateOfSale}</TableCell>
                    <TableCell align='right'>{row.billed}</TableCell>
                    <TableCell align='right'>{row.update}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const inputStyles = {
  width: 400,
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: gray,
    },
    underline: {
      '&::placeholder': {
        color: 'red',
        opacity: 1,
      }
    },
  },
  '& label.Mui-focused': {
    color: grayLightGhost,
  },
}

const boxSX = {
  background: gray,
  color:'#fff',
  width: '250px',
  height: '42px',
  "&:hover": {
    border: "1px solid #000",
    color: '#ffff',
    backgroundColor: grayLight
  },
};

const statusInProgress = (<Box sx={{ color: orangeInProcess }}>На рассмотрении</Box>)
const statusComplete = (<Box sx={{ color: 'green' }}>Одобрено</Box>)

const ActionWithInvoices = ({ handleClickToInvoicesDetails, pdfLink }) => (
  <>
    <Box sx={{ padding: '0px 0px 0px 8px' }}>
      <a style={{ textDecoration: 'none', color: gray, fontSize: '14px' }} href={`${pdfLink}`} target="blank">PDF</a>
    </Box>
    <Button sx={{
      color: grayLightGhost,
      fontSize: '12px',
      "&:hover": {
        color: gray,
        backgroundColor: 'inherit'
      }
    }}
      onClick={handleClickToInvoicesDetails}
    >
      Открыть
    </Button>
  </>
)

export default function OptionInvoices(props) {


  const navigate = useNavigate();
  const handleClickToInvoicesDetails = (id) => {  //navigate to details
    navigate(`/admin/invoices/details/${id}`);
  };

  const invoicesDataItem = props.invoicesData().map((item) => (

    createData(
      <ActionWithInvoices handleClickToInvoicesDetails={() => handleClickToInvoicesDetails(item.id)} key={uuidv4()} pdfLink={item.pdfLink} />,
      item.client,
      item.numberOfDocument,
      item.invoiceType,
      item.agent,
      (item.status === 'Одобрено' ? statusComplete : statusInProgress),
      `${item.vat}   `,
      item.currency,
      item.sum,
      item.dateOfCreation,
      item.dateOfSale,
      item.billed,
      item.update,
    )
  ));

  const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);

  return (

    <Box sx={{ maxWidth: '1400px', margin: '0 auto', boxShadow: 'none' }}>
      <Box sx={{
        width: '100%',
        position: 'sticky',
        zIndex: 4,
        top: '24px',
        padding: '46px 0px 33px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
        background: '#fff',
      }}>
        <Typography variant='h4'>Фактуры продажи</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '30px 0px 0px 0px' }}>
          <Box sx={{ width: '530px' }}>
            <TextField type="search" id="search" label="Поиск"
              sx={inputStyles}
              inputProps={{
                style: {
                  height: "11px",
                  '& .MuiInputInput::placeholder': {
                    color: 'gray',
                  }
                },
              }}
            />
          </Box>
          <Box sx={{
            width: '530px',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Link to='/admin/invoices/create'><Button sx={boxSX} variant="contained">+ СОЗДАТЬ ФАКТУРУ</Button></Link>





            <PopUp sx={boxSX}
              name={"+ Отчет VAT"}
              container={
                <ActionReportVat 
                header = 'Отчет VAT'
                handleClose={handleCloseAddClient}
                />
              }
              open={openAddClient}
              handleOpen={handleOpenAddClient}
              handleClose={handleCloseAddClient}
              width={'100%'}
            />
          </Box>
        </Box>
      </Box>
      <TableContainer sx={{ paddingTop: '40px', maxWidth: '1400px', margin: '0 auto', borderBottom: `1px solid ${gray}` }} >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: '20px' }} />
              <TableCell sx={{ minWidth: '80px' }}>Действия</TableCell>
              <TableCell sx={{ minWidth: '80px' }} align="right">Клиент</TableCell>
              <TableCell sx={{ minWidth: '80px' }} align="right">Документ</TableCell>
              <TableCell sx={{ minWidth: '80px' }} align="right">Тип</TableCell>
              <TableCell sx={{ minWidth: '80px' }} align="right">Контрагент</TableCell>
              <TableCell sx={{ minWidth: '80px' }} align="right">Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesDataItem.map((row) => (
              <Row key={uuidv4()} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}