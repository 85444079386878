import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionClientDetails } from "../../components/admin-page-components/admin-page-shells/OptionClientDetails";


const ClientDetails = (props) => {
    console.log('details')
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            login={props.login}
            handleLogout={props.handleLogout}
            container={<OptionClientDetails
                clientsData={props.clientsData}
                selectedClientId={props.selectedClientId} />} />
    )

}
export default ClientDetails;