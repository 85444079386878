import * as React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import AccountInfo from "./AccountInfo";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import { TextField,Alert } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import { useState,useEffect } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const grayLightGhost = blueGrey[300];

const inputStyles = {
    margin: '10px 0px 10px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

const boxSX = {
    background: gray,
    width: '40%',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};
const boxSXWhite = {
    border: `1px solid ${gray}`,
    width: '40%',
    height: 42,
    margin: '0px 0px 0px 30px',
    color: gray,
    "&:hover": {
        border: `1px solid ${grayLight}`,
        color: gray,
        background: grayGhost
    },
};
const boxMessage = {
    padding: '0px 0px 32px 0px'
  };


const ChangePass = (props) => {
    const token = Cookies.get('token');

    const [consent, setConsent] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const alertMessagehandler = (state) => {
        setAlertMessage(state)
    }

    

    const [currentPass, setCurrentPass] = useState('');

    const handleInputcurrentPass = (event) => {
        const value = event.target.value;
        setCurrentPass(value);
        console.log(value)
    };


    const [newPass, setNewPass] = useState('');

    const handleInputNewPass = (event) => {
        const value = event.target.value;
        setNewPass(value);
    };

    const [confirmPass, setConfirmPass] = useState('');

    const handleInputConfirmPass = (event) => {
        const value = event.target.value;
        setConfirmPass(value);
    };

    const cleanValues = (pass, newpass, confirm) => {

        pass('');
        newpass('');
        confirm('');
    };
    useEffect(() => {
        if(newPass === confirmPass){
            setConsent(true)
        }
        else{
            setConsent(false);
        }
        
    }, [newPass,confirmPass]);

    useEffect(() => {
        const timer = setTimeout(() => {
          setAlertMessage('');
        }, 2000);
    
        return () => {
          clearTimeout(timer);
        };
      }, [alertMessage]);
   
    const onClickSendButtonHandler = () => {    // push pass from back
        if(!consent) {
            alertMessagehandler(alertErrorMail);
        }
        if(consent) {
            try {
                axios.post(`https://crm.weexpert.io/api/update_user.php`, {
                    password: currentPass,
                    newpassword: newPass,
                },{
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                  }).then((response) => {
                    console.log(response)
                    alertMessagehandler(alertSuccess);
                    cleanValues(
                        setCurrentPass,
                        setNewPass,
                        setConfirmPass
                    );
                });
            } catch (e) {
                console.log(e);
    
            }
        } else console.log(' form not valid')
       
    };
    const alertErrorMail = <Alert severity="error">Пароли не совпадают</Alert>;
    const alertSuccess = <Alert severity="success">Данные успешно изменены</Alert>;
    return (
        <Grid container spacing={1} direction={'column'}>
            <Typography variant="h4" sx={{ padding: '30px 0px 30px 5px' }}>Смена пароля</Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 0px 24px 0px',
                maxWidth: '552px'
            }}>
                <AccountCircleIcon sx={{
                    width: 50,
                    height: 50,
                    opacity: 0.6
                }
                } />
                <Box sx={{
                    padding: '0px 0px 0px 20px'
                }}>
                    <AccountInfo userData={props.userData} />
                    
                </Box>
            </Box>
            
            <Box
                component="form"
                noValidate
                autoComplete="off"
                method="post"
                onSubmit={(e) => e.preventDefault()}
                sx={{ maxWidth: '552px' }}
            >
                <Box sx={boxMessage}>{alertMessage}</Box>
                <TextField sx={inputStyles}
                    fullWidth
                    id="currentPassword"
                    label="Актуальный пароль"
                    variant="outlined"
                    type="text"
                    value={currentPass}
                    onChange={handleInputcurrentPass}
                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="newPass"
                    label="Новый пароль"
                    variant="outlined"
                    type="password"
                    value={newPass}
                    onChange={handleInputNewPass}
                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="confirmNewPass"
                    label="Подтвердите новый пароль"
                    variant="outlined"
                    type="password"
                    value={confirmPass}
                    onChange={handleInputConfirmPass}
                />
                <Box sx={{
                    display: 'flex',
                    padding: '20px 0px 0px 0px'
                }}>
                    <Button sx={boxSX} variant="contained" onClick={() => {
                        onClickSendButtonHandler();
                    }} >Сохранить</Button>
                    <Button sx={boxSXWhite} variant="outlined" onClick={props.handlNewPass} >назад</Button>
                </Box>
            </Box>
        </Grid>
    )

}
export default ChangePass