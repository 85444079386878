import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import OptionWorkers from "../../components/admin-page-components/admin-page-shells/OptionWorkers";


const Workers = (props) => {
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            login={props.login}
            handleLogout={props.handleLogout}
            container={
                <OptionWorkers
                    
                />} />
    )

}
export default Workers;