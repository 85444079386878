import React from "react";
import { Box,Typography } from "@mui/material";
import axios from "axios";


 const AccountInfo =(props) =>{
    if (!props.userData) {
        return null;
      }
    return(
   
    <Box key={props.userData.id}>
        <Typography fontSize={16}>{`${props.userData.firstname}  ${props.userData.lastname}`}</Typography>
        <Typography fontSize={12}>{props.userData.rolename}</Typography>
    </Box>
          
    )

}
export default AccountInfo