import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { blueGrey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageSelector from '../language-selector/LanguageSelector';
import { useState,useEffect } from 'react';
import AccountInfo from '../account-components/AccountInfo';
import Cookies from 'js-cookie';
import axios from "axios";

const grayLight = blueGrey[700];
const drawerWidth = 240;
const gray = blueGrey[900];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const linkStyle = {
  width:'100%',
  textDecoration: 'none',
  color:grayLight,
  "&:hover": {
    border: "1px solid #000",
    color: '#ffff',
    backgroundColor: grayLight
  },
};

const linkButton ={
  "&:hover": {
    color:gray,
    background:'#ffff'
  },
};

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const token = Cookies.get('token');

  /*const getUserInfo = () => {
    try {
      axios.post(`https://crm.weexpert.io/api/validate_token.php`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        const user = response.data
       setUserData(user);
      });
    } catch (e) {
      console.log(e);
    }
  };*/
  
  useEffect(() => {
    props.getUserInfo();
  }, [token]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar sx={{display:'flex',justifyContent:'space-between',background:gray,color:'#fff'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{position:'absolute',left:'80px',top:'15px'}}>
            weexpert CRM
          </Typography>
          <LanguageSelector handleLogout={props.handleLogout}/>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              textAlign:'center', 
              flexWrap:'wrap',
              padding:"40px 40px 40px 10px"
              }}>
                <Box sx={{}}>
                    <AccountCircleIcon sx={{width:50,height:50,opacity:0.6,}}/>
                </Box>

                {/* account info data */}

                <Link to='/account' style={{textDecoration:'none',color:gray}}>
                  <AccountInfo userData ={props.userData}/> {/* ошибка */}
                </Link>
            </Box>
        </List>
        <List>

          {/* navigation menu data */}

          {props.navData().map((item, index) => (
            <ListItem key={index}  disablePadding>
              <Link  style={linkStyle} to={`${item.link}`}>
                <ListItemButton sx={linkButton}>
                  <ListItemIcon sx={{}}>
                    {item.img}
                  </ListItemIcon>
                  <Box>{item.nav}</Box>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main  open={open}>
        <DrawerHeader />
        {props.container}
      </Main>
    </Box>
  );
}