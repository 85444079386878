import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionContractorsCreate } from "../../components/admin-page-components/admin-page-shells/OptionContractorsCreate";

export const CreateContractor = (props) =>{
    
    return(
        <SideNav 
        getUserInfo={props.getUserInfo}
        navData={props.navData} 
        userData ={props.userData} 
        handleLogout={props.handleLogout}
        container={
        <OptionContractorsCreate

        clientsData={props.clientsData}
        />}/>
    )

}