import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionInvoicesCreate } from "../../components/admin-page-components/admin-page-shells/OptionInvoicesCreate";

const InvoicesCreate = (props) => {
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            handleLogout={props.handleLogout}
            container={<OptionInvoicesCreate
                clientsData={props.clientsData}
                contractorsData={props.contractorsData}
            />} />
    )

}
export default InvoicesCreate