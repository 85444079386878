import * as React from "react";
import { Box, Typography, Button, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { blueGrey } from "@mui/material/colors";
import { ActionWithClientInDetails } from "../administration-tables-controls-components/ActionWithClientInDetails";
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const grayGhost400 = blueGrey[400];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = { 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'space-between', 
    flexWrap: 'wrap' 
}

const typoCapture = { 
    fontSize: '14px'
 }

const typoDescription = { 
    fontWeight: '600', 
    fontSize: '14px' 
}

export const OptionClientDetails = (props) => {

    const {id} = useParams();
    console.log(typeof(id))

    const clientItem = (data, id) => {
        let client;
        data.forEach((item) => {
            if (item.id == id) {
                client = item;
            }
        });
        return client;
    };

    const itemData = clientItem(props.clientsData(), id);
   
    return (
        <Box sx={{
            maxWidth: '1400px', margin: '0 auto'
        }} >
            <Typography sx={{ padding: '30px 0px 30px 0px', maxWidth: '100%' }} variant='h4'>Детали клиента</Typography>
            <Box sx={{
                maxWidth: '1400px', margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '30px 0px 30px 0px'
            }}>
                <Box>
                    <Typography variant='h7'>Клиент</Typography>
                    <Typography variant='h5'>{itemData ? itemData.fullName : 'N/A'}</Typography>
                </Box>
                <Link to={`/admin/client/details/edit/${id}`}><Button sx={boxSX} variant="contained">{<EditIcon sx={{ marginRight: '20px' }} />} изменить</Button></Link>
            </Box>
            <Grid container spacing={2} sx={{ maxWidth: '100%'}}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Основная информация</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Имя</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.name : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Email</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.email : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Гражданство</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.citizenship : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>PESEL</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.pesel : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Банковский счет в инкубаторе</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.incubatorPaymentAccount : 'N/A'}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Фамилия</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.surname : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Номер телефона</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.tel : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Дата рождения</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.dateOfBirth : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Паспорт</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.passport : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Банковский счет клиента</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.clientPaymentAccount : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Дополнительная информация</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Статус</Typography>
                                <Typography sx={typoDescription}>{itemData ? (itemData.status === 'active' ? 'Активный' : 'Неактивный') : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Ассистент</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.assistant : 'N/A'}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Добавлен</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.dateOfCreation : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Легализация</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.legalization : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '500',padding:'40px 0px 0px 0px' }}>Действия</Typography>
                    <ActionWithClientInDetails/>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Адрес в Польше</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Воеводство</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.region : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Почтовый индекс</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.mailIndex : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Номер дома</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.houseNumber : 'N/A'}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Город</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.city : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Улица</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.street : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Номер квартиры</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.appartmentNumber : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}