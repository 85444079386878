
import * as React from "react";
import { Box, Typography, Button, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { blueGrey } from "@mui/material/colors";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const boxSX = {
    background: gray,
    width: '210px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxSXWhite = {
    border: `1px solid #fff`,
    width: '210px',
    height: 42,
    margin: '0px 20px 0px 0px',
    background: '#fff',
    color: gray,
    "&:hover": {
        border: `1px solid ${gray}`,
        color: gray,
        background: grayGhost
    },
}

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
};

const typoCapture = {
    fontSize: '14px'
};

const typoDescription = {
    fontWeight: '600',
    fontSize: '14px'
};

const tableStyle = {
    borderCollapse: 'collapse',
    boxShadow: 'none',
};

const cellStyle = {
    border: 'none',
    padding: '8px 16px',
};
const containerStyle = {
    boxShadow: 'none',
};

function createData(name, pkwiu, quantity, unit, unitPriceNetto, priceNetto, vatRate, vat, priceBrutto) {
    return { name, pkwiu, quantity, unit, unitPriceNetto, priceNetto, vatRate, vat, priceBrutto };
}

export default function BasicTable(props) {
    const rows = props.data.positionData.map((item) => (
        createData(
            item.name,
            item.pkwiu,
            item.quantity,
            item.unit,
            item.unitPriceNetto,
            item.priceNetto,
            item.vatRate,
            item.vat,
            item.priceBrutto
        )
    ));
    return (
        <TableContainer component={Paper} style={containerStyle}>
            <Table style={tableStyle} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={cellStyle} >Название</TableCell>
                        <TableCell style={cellStyle} align="right">PKWIU</TableCell>
                        <TableCell style={cellStyle} align="right">кол-во</TableCell>
                        <TableCell style={cellStyle} align="right">Единица</TableCell>
                        <TableCell style={cellStyle} align="right">Цена за ед. нетто</TableCell>
                        <TableCell style={cellStyle} align="right">Цена нетто</TableCell>
                        <TableCell style={cellStyle} align="right">Ставка VAT</TableCell>
                        <TableCell style={cellStyle} align="right">VAT</TableCell>
                        <TableCell style={cellStyle} align="right">Цена брутто</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={uuidv4()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.pkwiu}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{row.unit}</TableCell>
                            <TableCell align="right">{row.unitPriceNetto}</TableCell>
                            <TableCell align="right">{row.priceNetto}</TableCell>
                            <TableCell align="right">{row.vatRate}</TableCell>
                            <TableCell align="right">{row.vat}</TableCell>
                            <TableCell align="right">{row.priceBrutto}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const OptionInvoicesInfo = (props) => {

    const { id } = useParams();

    const clientItem = (data, id) => {
        let client;
        data.forEach((item) => {
            if (item.id == id) {  // i dont know what type of key i get 
                client = item;
            }

        });
        return client;
    };

    const itemData = clientItem(props.invoicesData(), id);
    console.log(itemData)

    return (
        <Box sx={{
            maxWidth: '1400px', margin: '0 auto'
        }} >
            <Box sx={{
                maxWidth: '1400px', margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '30px 0px 30px 0px'
            }}>
                <Box>
                    <Typography variant='h4'>{`Фактура (ID:${id})`}</Typography>
                </Box>
                <Box>
                    <Button sx={boxSXWhite} variant="contained">{<DeleteIcon sx={{ marginRight: '20px' }} />} Удалить</Button>
                    <Button sx={{ ...boxSX, marginRight: '20px' }} variant="contained">{<DownloadIcon sx={{ marginRight: '20px' }} />} PDF</Button>
                    <Link to={`/admin/invoices/details/edit/${id}`}><Button sx={boxSX} variant="contained">{<EditIcon sx={{ marginRight: '20px' }} />} изменить</Button></Link>
                </Box>
            </Box>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Основные данные</Typography>
            <Grid container sx={{ maxWidth: '100%', padding: '20px 0px 0px 0px' }}>
                <Grid item xs={6}>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Клиент</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.client : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Тип фактуры</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.invoiceType : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Номер документа</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.numberOfDocument : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Дата выставления</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.dateOfCreation : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Дата продажи</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.dateOfSale : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Статус</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.status : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Данные контрагента</Typography>
            <Grid container sx={{ maxWidth: '100%', padding: '20px 0px 0px 0px' }}>
                <Grid item xs={6}>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Контрагент</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.contractor : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Происхождение</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.origin : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Название</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.name : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>NIP</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.nip : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Email</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.mail : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Тип</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.invoiceType : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Адрес</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.adress : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Почтовый индекс</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.mailIndex : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Город</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.city : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Страна</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.country : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ maxWidth: '100%', padding: '20px 0px 0px 0px' }}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Оплата</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Транкзакция</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.transaction : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={{ fontSize: '14px' }}>Способ оплаты</Typography>
                                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{itemData ? itemData.paymentMethod : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Статус оплаты</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.paymentStatus : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Оплата до</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.paymentTerm : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Валюта</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.currency : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Курс обмена на PLN</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.currencyCourse : 'N/A'}</Typography>
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Банковский счет</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.paymentAccount : 'N/A'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Комментарий на фактуре</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Комментарий на фактуре</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.invoiceComment : 'N/A'}</Typography>
                            </Box>

                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Комментарий внутренний</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '234px', padding: '20px 0px 20px 20px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Typography sx={typoCapture}>Тип</Typography>
                                <Typography sx={typoDescription}>{itemData ? itemData.internalComment : 'N/A'}</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Позиции</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                <BasicTable data={itemData} />
            </Box>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Сводка</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                <Typography sx={typoCapture}>Цена нетто</Typography>
                <Typography sx={typoDescription}>{`${itemData.summaryNetto} ${itemData.currency}`}</Typography>
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
                <Typography sx={typoCapture}>VAT</Typography>
                <Typography sx={typoDescription}>{`${itemData.summaryVat} ${itemData.currency}`}</Typography>
            </Box>
            <Box sx={{ padding: '20px 0px 30px 0px' }}>
                <Typography sx={typoCapture}>Цена брутто</Typography>
                <Typography sx={typoDescription}>{`${itemData.summaryBrutto} ${itemData.currency}`}</Typography>
            </Box>
        </Box>
    )
}