import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import AccountForm from "../../components/account-components/AccountForm";
import ChangePass from "../../components/account-components/ChangePass";


function Account(props) {


    return (
        <>
            <SideNav
            getUserInfo={props.getUserInfo}
                rights={props.rights}
                navData={props.navData}
                handleLogout={props.handleLogout}
                userData={props.userData}
                login={props.login}
                container={
                    props.newPass ? <ChangePass
                        rights={props.rights}
                        userData={props.userData}
                        handlNewPass={props.handlNewPass} /> : <AccountForm
                        getUserInfo={props.getUserInfo}
                        userDataHandler={props.userDataHandler}
                        rights={props.rights}
                        userData={props.userData}
                        handlNewPass={props.handlNewPass} />
                }
            />
        </>
    );

}
export default Account;
