import React from "react";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import loginLogo from '../../assets/img/loginLogo.svg';
import { Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { blueGrey } from "@mui/material/colors";
import axios from "axios";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import Cookies from 'js-cookie';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const grayLightGhost = blueGrey[300];

const gridStyles = {
  width: '100%',
  height: '100vh',
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center'
};

const boxStyles = {
  width: '320px',
};

const boxImg = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '0px 0px 32px 0px'
};

const boxMessage = {
  padding: '0px 0px 32px 0px'
};

const inputStyles = {
  margin: '10px 0px 10px 0px',
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: gray,
    },
    underline: {
      '&::placeholder': {
        color: 'red',
        opacity: 1,
      }
    },
  },
  '& label.Mui-focused': {
    color: grayLightGhost,
  },
};

export const Login = (props) => {

  const [alertMessage, setAllertMessage] = useState();
  const alertMessagehandler = (value) => {
    setAllertMessage(value)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllertMessage('');
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [alertMessage]);
  const [mail, setMail] = useState('');
  const [pass, setPass] = useState('');
  const [mailError, setMailError] = useState('');

  const setPassHandler = (event) => {
    setPass((prev) => {
      prev = event.target.value;
      console.log(prev)
      return prev;
    });
  };

  const setMailHandler = (event) => {
    setMail((prev) => {
      prev = event.target.value;
      console.log(prev)
      const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
        setMailError(() => {
          setAllertMessage(alertErrorMail)
        })
      } else
        setMailError(() => {
          setAllertMessage('')
        })
      return prev;
    });
  };

  const cleanValues = (mail, password) => {
    console.log("cleaned")
    mail('');
    password('');
  }

  const onClickSendButtonHandler = () => {    ////////////////////////////////////////
    try {
      axios.post(`https://crm.weexpert.io/api/login.php`, {
        email: mail,
        password: pass
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        const responseData = response.data
        console.log(responseData)

        if (responseData.jwt) {
          Cookies.set('token', `${responseData.jwt}`, { expires: 1 });
          props.userDataHandler(responseData);         ////
          props.currentRoleHandler(responseData.role);              ////поменять на роль
          props.login();
          cleanValues(setMail, setPass);
        }
        else {
          cleanValues(setMail, setPass);
          alertMessagehandler(alertErrorMailAndMessage);
        }

      });
    } catch (e) {
      console.log(e);
    }
  };



  const alertErrorMailAndMessage = <Alert severity="error">Неверный email или пароль</Alert>;
  const alertErrorMail = <Alert severity="error">Неверный  адрес email </Alert>;

  const boxSX = {
    background: gray,
    width: '100%',
    height: '42px',
    "&:hover": {
      border: "1px solid #000",
      color: '#ffff',
      backgroundColor: grayLight
    },
  };

  return (
    <>
      <AppBarForLogin handleLogout={props.handleLogout} />
      <Grid sx={gridStyles}>
        <Box
          component="form"
          sx={boxStyles}
          noValidate
          autoComplete="off"
          method="post"
          onSubmit={(e) => e.preventDefault()}
        >
          <Box sx={boxImg}><img sx={{ width: '100%', display: 'block', }} src={loginLogo} alt="logo"></img></Box>
          <Box sx={boxMessage}>{alertMessage}</Box>
          <TextField sx={inputStyles}
            fullWidth
            label="Email"
            variant="outlined"
            type="email"
            value={mail}
            onChange={setMailHandler}
          />
          <TextField
            sx={inputStyles}
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            value={pass}
            onChange={setPassHandler}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ height: 22, margin: '16px 0px 16px 180px' }}><Link style={{ textDecoration: 'none', color: gray }} to='restore'>Забыл пароль</Link></Button>
          </Box>
          <Button sx={boxSX} type="submit" variant="contained" onClick={() => {
            onClickSendButtonHandler();
          }} >Войти</Button>

        </Box>
      </Grid>
    </>
  );
}

