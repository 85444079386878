import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import { Alert } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { MainCreateInvoicesInputs } from "../admin-create-components/invoice-create-components/MainCreateInvoicesInputs";
import { ContractosInputsBlock } from '../admin-create-components/invoice-create-components/ContractosInputsBlock';
import { PaymentBlock } from '../admin-create-components/invoice-create-components/PaymentBlock';
import { PositionBlock } from "../admin-create-components/invoice-create-components/PositionBlock";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    width: '100%',
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const OptionInvoicesCreate = (props) => {

    const currencies = [
        {
            value: 'USD',
            label: 'USD',
        },
        {
            value: 'EUR',
            label: 'EUR',
        },
        {
            value: 'PLN',
            label: 'PLN',
        },
        {
            value: 'GBP',
            label: 'GBP',
        },
        {
            value: 'CHF',
            label: 'CHF',
        },
    ];

    const countryData = [
        {
            id: 'poland',
            value: 'Польша'
        },
        {
            id: 'eu',
            value: 'ЕС'
        },
        {
            id: 'otherWorld',
            value: 'Остальной мир'
        },
    ];
    const companyData = [
        {
            id: 'company',
            value: 'Компания'
        },
        {
            id: 'individual',
            value: 'Физическое лицо'
        },
    ];
    const transactionData = [
        {
            id: 'local',
            value: 'Локальная'
        },
        {
            id: 'overseas',
            value: 'Заграничная'
        }
    ];

    const paymentMethod = [
        {
            id: 'paymentMethod',
            value: 'На банковский счет'
        }
    ];

    const paymentStatus = [
        {
            id: 'paymentStatusOk',
            value: 'Оплачено'
        },
        {
            id: 'paymentStatusWait',
            value: 'Не оплачено'
        }
    ];
    const statusData = [
        {
            id: 'onReview',
            value: 'На рассмотрении'
        },
        {
            id: 'approved',
            value: 'Одобрено'
        }
    ];

    const get_new_row =() => {
        return {
            id: uuidv4(),
            name: '',
            pkwiu: '',
            quantity: 0,
            unit: '',
            unitPriceNetto: 0,
            priceNetto: 0,
            vatRate: '',
            vat: 0,
            priceBrutto: 0,
        };
    }
    const [rows, setRows] = useState([get_new_row()]); // initial state

    const addNewRow = () => {
        const newRow =get_new_row();
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    //function that receives data and fills array of objects

    const handleRowFieldChange = (id, fieldName, value) => {
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row) => {
                if (row.id === id) {
                    let updatedquantity = row.quantity;
                    let updatedUnitPriceNetto = row.unitPriceNetto;
                    let updatedPriceNetto = row.priceNetto;
                    let updatedVat = row.vat;

                    if (fieldName === 'quantity') {
                        updatedquantity = value;
                    } else if (fieldName === 'unitPriceNetto') {
                        updatedUnitPriceNetto = value;
                    }

                    const quantity = parseFloat(updatedquantity) || 0;
                    const unitPriceNetto = parseFloat(updatedUnitPriceNetto) || 0;
                    updatedPriceNetto = (quantity * unitPriceNetto).toFixed(2);

                    if (fieldName === 'vatRate' && ['23%', '8%', '5%', '0%'].includes(value)) {
                        const vatRateValue = parseFloat(value.replace('%', ''));
                        if (!isNaN(vatRateValue) && !isNaN(updatedPriceNetto)) {
                            updatedVat = ((parseFloat(updatedPriceNetto)).toFixed(2) / 100) * vatRateValue;
                        }
                    }

                    let updatedPriceBrutto = row.priceBrutto;

                    if (!isNaN(updatedPriceNetto) && !isNaN(updatedVat)) {
                        updatedPriceBrutto = (parseFloat(updatedPriceNetto) + parseFloat(updatedVat)).toFixed(2);
                    }

                    const updatedRow = {
                        ...row,
                        [fieldName]: value,
                        quantity: updatedquantity,
                        unitPriceNetto: updatedUnitPriceNetto,
                        priceNetto: updatedPriceNetto,
                        vat: updatedVat,
                        priceBrutto: updatedPriceBrutto
                    };
                    return updatedRow;
                }
                return row;
            });
            return updatedRows;
        });
    };

    //states for alert message//

    const [alertMessage, setAllertMessage] = useState();
    const alertErrorIncorrectText = <Alert severity="error">Только латинские буквы</Alert>;
    const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;
    const alertSuccess = <Alert severity="success">Данные успешно изменены</Alert>;

    //states for section main data//

    const [currentClient, setCurrentClient] = useState("");
    const handleChangeClient = (event) => {   //get client
        setCurrentClient(event.target.value);
        console.log(event.target.value)
    };

    const [currentInvoice, setCurrentInvoice] = useState("");
    const handleChangeInvoice = useCallback((event) => {  //set type of invoice
        setCurrentInvoice(event.target.value);
        console.log(event.target.value)
    }, []);

    //state for autogenerate number of document needed

    const [dateTime, setDateTime] = useState('');       // current date&time
    const formatDateTime = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    useEffect(() => {
        setDateTime(formatDateTime());
    }, []);

    const [currentStatus, setCurrentStatus] = useState(statusData[0].value);
    const handleChangeStatus = (event) => {   //get StatusType
        setCurrentStatus(event.target.value);
        console.log(event.target.value)
    };


    const [selectedDateOfSale, setSelectedDateOfSale] = useState(dayjs());   // data sale with calendar
    const handleDateChangeDateOfSale = (date) => {
        setSelectedDateOfSale(date);
        console.log(selectedDateOfSale.format('MM/DD/YYYY'))
    };

    //end//

    //states for section contractors //

    const [currentContractors, setCurrentContractors] = useState("");
    const handleChangeContractors = (event) => {   //get Contractors
        setCurrentContractors(event.target.value);
        handleChangeNameForContractorsBlock(event.target.value);  // set state for currentNameForContractorsBlock
        console.log(event.target.value)
    };

    const [currentCountry, setCurrentCountry] = useState('');
    const handleChangeCountry = (event) => {   //get country
        setCurrentCountry(event.target.value);
        console.log(event.target.value)
    };

    const [currentNameForContractorsBlock, setCurrentNameForContractorsBlock] = useState('');
    const handleChangeNameForContractorsBlock = (value) => {   //get NameForContractorsBlock
        setCurrentNameForContractorsBlock(value);
        console.log(currentNameForContractorsBlock)
    };

    const [currentNip, setCurrentNip] = useState('');
    const handleChangeNip = (value) => {   //get NIP
        setCurrentNip(value);
        console.log(value)
    };

    const [mail, setMail] = useState('');
    const setMailHandler = (value) => {
        setMail(value);
        console.log(value)
    };

    const [currentCompanyType, setCurrentCompanyType] = useState('');
    const handleChangeCompanyType = (event) => {   //get type company or individual
        setCurrentCompanyType(event.target.value);
        console.log(event.target.value)
    };

    const [currentAdress, setCurrentAdress] = useState('');
    const handleChangeAdress = (value) => {   //get adress
        setCurrentAdress(value);
        console.log(value)
    };

    const [currentMailIndex, setCurrentMailIndex] = useState('');
    const handleChangeMailIndex = (value) => {   //get mail index
        setCurrentMailIndex(value);
        console.log(value)
    };

    const [currentCity, setCurrentCity] = useState("");
    const handleChangeCity = (value) => {   //get valid city
        setCurrentCity(value);
        console.log(value)
    };

    const [currentCountryOfCompany, setCurrentCountryOfCompany] = useState("");
    const handleChangeCountryOfCompany = (value) => {   //get company registration country
        setCurrentCountryOfCompany(value);
        console.log(value)
    };

    //end//

    //states for section payment block //

    const [currentTransactionType, setCurrentTransactionType] = useState('');
    const handleChangeTransactionType = (event) => {   //get type of transaction
        setCurrentTransactionType(event.target.value);
        console.log(event.target.value)
    };
    const [currentPaymentType, setCurrentPaymentType] = useState('');
    const handleChangePaymentType = (event) => {   //get payment type
        setCurrentPaymentType(event.target.value);
        console.log(event.target.value)
    };

    const [currentPaymentStatus, setCurrentPaymentStatus] = useState('');
    const handleChangePaymentStatus = (event) => {   //get payment status
        setCurrentPaymentStatus(event.target.value);
        console.log(event.target.value)
    };

    const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(dayjs());   // data for calendar
    const handleDateChangePaymentTerm = (date) => {
        setSelectedPaymentTerm(date);
        console.log(selectedPaymentTerm.format('MM/DD/YYYY'))
    };

    const [currentCurrency, setCurrentCurrency] = useState("");
    const handleChangeCurrency = (event) => {   //get valid currency
        setCurrentCurrency(event.target.value);
        console.log(event.target.value)
    };

    const [currentBankAccount, setCurrentBankAccount] = useState("");
    useEffect(() => {
        const foundClient = props.clientsData().find((item) => currentClient === item.fullName); //get payment account

        if (foundClient) {
            handleChangeBankAccount(foundClient.clientPaymentAccount);
        }
    }, [currentClient]);

    const handleChangeBankAccount = (value) => {
        setCurrentBankAccount(value);
        console.log(value);
    };

    const [currentInvoiceComment, setCurrentInvoiceComment] = useState("");
    const handleChangeInvoiceComment = (event) => {   //get invoice Comment
        setCurrentInvoiceComment(event.target.value);
        console.log(event.target.value)
    };

    const [currentInternalComment, setCurrentInternalComment] = useState("");
    const handleChangeInternalComment = (event) => {   //get Internal invoice Comment
        setCurrentInternalComment(event.target.value);
        console.log(event.target.value)
    };


    useEffect(() => {
        const contractorItem = props.contractorsData().find((item) => currentContractors === item.fullName);

        if (contractorItem) {
            handleChangeNip(contractorItem.nip);
            setMailHandler(contractorItem.mail);
            setCurrentAdress(contractorItem.adress);
            setCurrentMailIndex(contractorItem.mailIndex);
            setCurrentCity(contractorItem.city);
            handleChangeCountryOfCompany(contractorItem.country)
        }
    }, [currentContractors]);

    //end//


    //report states//
    const [priceNetto, setPriceNetto] = useState(0); // netto summary

    const priceNettoHandler = (value) => {
        setPriceNetto(value);
        console.log(value);
    };
    
    let summaryNetto = 0; 
    
    rows.forEach((item) => {
        summaryNetto += parseFloat(item.priceNetto);
    });
    
    useEffect(() => {
        priceNettoHandler(summaryNetto); 
    }, [summaryNetto]);


    let summaryVat = 0;
    const [reportVat, setReportVat] = useState(0)   //report VAT

    const reportVatHandler = (value) => {
        setReportVat(value);
        console.log(value);
    };

    rows.forEach((item) => {
        summaryVat += parseFloat(item.vat);
    });
    
    useEffect(() => {
        reportVatHandler(summaryVat); 
    }, [summaryVat]);

    let summaryBrutto = 0;
    const [priceBrutto, setPriceBrutto] = useState(0)   //report priceBrutto

    const priceBruttoHandler = (value) => {
        setPriceBrutto(value);
        console.log(value);
    };

    rows.forEach((item) => {
        summaryBrutto += parseFloat(item.priceBrutto);
    });
    
    useEffect(() => {
        priceBruttoHandler(summaryBrutto.toFixed(2)); 
    }, [summaryBrutto]);

    let vatRateArray=[]                                    //array with vatRates
    rows.forEach((item) =>{
        vatRateArray.push(item.vatRate)
    });
    console.log(vatRateArray)
    //end //


    /*
    async function fetchExchangeRates() {
        const apiUrl = "https://api.exchangeratesapi.io/v1/latest?access_key=ecccecdbd0f41a84e90847c393074647";    //api for rates needed
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();
          if (data.success) {
            const rates = data;
            console.log(rates)
          } else {
            console.error("API request was not successful.");
          }
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      }
      fetchExchangeRates();
*/console.log(currentClient)
    return (
        <Box sx={{
            maxWidth: '1400px', margin: '0 auto'
        }} >
            <Box sx={{
                maxWidth: '1400px', margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                background: '#fff',
                alignItems: 'center',
                position: 'sticky',
                zIndex: 4,
                top: '24px',
                padding: '46px 0px 33px 0px',

            }}>
                <Typography sx={{ padding: '30px 0px 30px 0px', maxWidth: '100%' }} variant='h4'>Новая фактура</Typography>
                <Button sx={boxSX} variant="contained"> Сохранить</Button>
            </Box>
            {/* main data*/}
            <MainCreateInvoicesInputs
                currentClient={currentClient}
                clientsData={props.clientsData}
                handleChangeClient={handleChangeClient}
                currentInvoice={currentInvoice}
                handleChangeInvoice={handleChangeInvoice}
                dateTime={dateTime}
                formatDateTime={formatDateTime}
                statusData={statusData}
                currentStatus={currentStatus}
                handleChangeStatus={handleChangeStatus}
                selectedDateOfSale={selectedDateOfSale}
                handleDateChangeDateOfSale={handleDateChangeDateOfSale}
            />
            <ContractosInputsBlock
                currentContractors={currentContractors}
                handleChangeContractors={handleChangeContractors}
                contractorsData={props.contractorsData}
                countryData={countryData}
                currentCountry={currentCountry}
                handleChangeCountry={handleChangeCountry}
                currentCompanyType={currentCompanyType}
                handleChangeCompanyType={handleChangeCompanyType}
                companyData={companyData}
                currentNameForContractorsBlock={currentNameForContractorsBlock}
                handleChangeNameForContractorsBlock={handleChangeNameForContractorsBlock}
                mail={mail}
                setMailHandler={setMailHandler}
                alertMessage={alertMessage}
                currentNip={currentNip}
                handleChangeNip={handleChangeNip}
                currentAdress={currentAdress}
                handleChangeAdress={handleChangeAdress}
                currentMailIndex={currentMailIndex}
                handleChangeMailIndex={handleChangeMailIndex}
                currentCity={currentCity}
                handleChangeCity={handleChangeCity}
                currentCountryOfCompany={currentCountryOfCompany}
                handleChangeCountryOfCompany={handleChangeCountryOfCompany}
                currentClient={currentClient}

            />
            <PaymentBlock
                currentTransactionType={currentTransactionType}
                handleChangeTransactionType={handleChangeTransactionType}
                transactionData={transactionData}
                currentPaymentType={currentPaymentType}
                handleChangePaymentType={handleChangePaymentType}
                paymentMethod={paymentMethod}
                currentPaymentStatus={currentPaymentStatus}
                handleChangePaymentStatus={handleChangePaymentStatus}
                paymentStatus={paymentStatus}
                currentCurrency={currentCurrency}
                handleChangeCurrency={handleChangeCurrency}
                currencies={currencies}
                selectedPaymentTerm={selectedPaymentTerm}
                handleDateChangePaymentTerm={handleDateChangePaymentTerm}
                currentBankAccount={currentBankAccount}
                handleChangeBankAccount={handleChangeBankAccount}
                currentInternalComment={currentInternalComment}
                handleChangeInternalComment={handleChangeInternalComment}
                currentInvoiceComment={currentInvoiceComment}
                handleChangeInvoiceComment={handleChangeInvoiceComment}
                currentClient={currentClient}
            />
            <PositionBlock
                
                rows={rows}
                handleRowFieldChange={handleRowFieldChange}
                handleDeleteRow={handleDeleteRow}
                addNewRow={addNewRow}
            />
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Сводка</Typography>
            <Box sx={{ paddingTop: '20px' }}>
                <TextField size="small" sx={{ ...inputStyles, width: '400px' }}
                    fullWidth
                    id="adress"
                    label="Цена нетто"
                    variant="outlined"
                    value={isNaN(priceNetto) ? '' : parseFloat(priceNetto)}
                    
                   
                />
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
                <TextField size="small" sx={{ ...inputStyles, width: '400px', padding: '0px 0px 0px 0px' }}
                    fullWidth
                    id="bankAccount"
                    label="VAT"
                    variant="outlined"
                    type="text"
                    value={!isNaN(reportVat) ? reportVat : ''}
                />
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
                <TextField size="small" sx={{ ...inputStyles, width: '400px', padding: '0px 0px 60px 0px' }}
                    fullWidth
                    id="bankAccount"
                    label="Цена брутто"
                    variant="outlined"
                    type="text"
                    value={!isNaN(priceBrutto) ? priceBrutto : ''}
                />
            </Box>
        </Box>
    )
}