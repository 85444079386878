import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { blueGrey } from "@mui/material/colors";

const gray = blueGrey[900];


export default function PopUp(props) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth:props.width,
    bgcolor: 'background.paper',
    border: `1px solid ${gray}`,
    boxShadow: 0,
    p: 4,
  };
  
  
  return (
    <div>
      <Button sx={props.sx} onClick={props.handleOpen}>{props.name}</Button>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {props.container}
        </Box>
      </Modal>
    </div>
  );
}