import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PersonIcon from '@mui/icons-material/Person';
import { v4 as uuidv4 } from 'uuid';

function userData() {
  return [
    {
      id: 1,
      name: 'Michael Jackson',
      rights: 'administrator',
    }
  ]
};

function indicatorsKPI() {
  return [
    {
      totalClients: 135,
      activeClients: 100,
      nonActiveCluents: 35,
      invoices: 56,
      contracts: 10
    }
  ]
}

function adminNavData() {
  return [
    {
      nav: 'Главная',
      link: '/',
      img: <HomeIcon />
    },
    {
      nav: 'Администрация',
      link: '/admin/administration',
      img: <AdminPanelSettingsRoundedIcon />
    },
    {
      nav: 'Клиенты',
      link: '/admin/clients',
      img: <SupervisorAccountRoundedIcon />
    },
    {
      nav: 'Фактуры',
      link: '/admin/invoices',
      img: <ReceiptLongIcon />
    },
    {
      nav: 'Кадры',
      link: '/admin/workers',
      img: <ContactPageIcon />
    },
    {
      nav: 'Контрагенты',
      link: '/admin/contractors',
      img: <PersonIcon />
    }
  ]
};
function administrationData() {
  return [
    {
      name: 'Иванов Иван',
      email: 'ddd@dd.dd',
      tel: '+380667007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Атб', 'Ciльпо', 'Малинка'],

    },
    {
      name: 'Петров Николай',
      email: 'ии@dd.dd',
      tel: '+380667002307',
      rights: 'User',
      status: 'inactive',
      dateOfCreation: '18.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр'],

    },
    {
      name: 'Головань Дмитрий',
      email: 'ddd@dd.dd',
      tel: '+380967007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Вурветов Максим',
      email: 'ddd@dd.dd',
      tel: '+380977007007',
      rights: 'assistant',
      status: 'active',
      dateOfCreation: '05.10.2023',
      clients: ['Епицентр', 'Малинка'],

    },
    {
      name: 'Иванов Иван',
      email: 'ddd@dd.dd',
      tel: '+380667007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Петров Николай',
      email: 'ии@dd.dd',
      tel: '+380667002307',
      rights: 'User',
      status: 'inactive',
      dateOfCreation: '18.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Головань Дмитрий',
      email: 'ddd@dd.dd',
      tel: '+380967007007',
      rights: 'administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Вурветов Максим',
      email: 'ddd@dd.dd',
      tel: '+380977007007',
      rights: 'administrator',
      status: 'active',
      dateOfCreation: '05.10.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Иванов Иван',
      email: 'ddd@dd.dd',
      tel: '+380667007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Атб', 'Ciльпо', 'Малинка'],

    },
    {
      name: 'Петров Николай',
      email: 'ии@dd.dd',
      tel: '+380667002307',
      rights: 'User',
      status: 'inactive',
      dateOfCreation: '18.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр'],

    },
    {
      name: 'Головань Дмитрий',
      email: 'ddd@dd.dd',
      tel: '+380967007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Вурветов Максим',
      email: 'ddd@dd.dd',
      tel: '+380977007007',
      rights: 'assistant',
      status: 'active',
      dateOfCreation: '05.10.2023',
      clients: ['Епицентр', 'Малинка'],

    },
    {
      name: 'Иванов Иван',
      email: 'ddd@dd.dd',
      tel: '+380667007007',
      rights: 'Administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Петров Николай',
      email: 'ии@dd.dd',
      tel: '+380667002307',
      rights: 'User',
      status: 'inactive',
      dateOfCreation: '18.04.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Головань Дмитрий',
      email: 'ddd@dd.dd',
      tel: '+380967007007',
      rights: 'administrator',
      status: 'active',
      dateOfCreation: '20.04.2023',
      clients: ['Ciльпо', 'Епицентр', 'Малинка'],

    },
    {
      name: 'Вурветов Максим',
      email: 'ddd@dd.dd',
      tel: '+380977007007',
      rights: 'administrator',
      status: 'active',
      dateOfCreation: '05.10.2023',
      clients: ['Атб', 'Ciльпо', 'Епицентр', 'Малинка'],

    },

  ];
}
function clientsData() {
  return [
    {
      id: 1,
      fullName: 'Slusarenko Ivan',
      name: 'Ivan',
      surname: 'Slusarenko',
      email: 'zddd@dd.dd',
      tel: '+380667007007',
      status: 'active',
      dateOfBirth: '20.04.1986',
      dateOfCreation: '20.04.2023',
      assistant: 'Nikolay Ivanov',
      citizenship: 'Ukrainian',
      pesel: '1234567890',
      incubatorPaymentAccount: '22 33 456456 887 5445 66',
      passport: 'BE211436',
      clientPaymentAccount: '1234 5456 7887 44 8878',
      legalization: 'карта побуту',
      region: 'Mazowieckie',
      city: 'Warszawa',
      mailIndex: '40-357',
      street: '3 Maja',
      appartmentNumber: '-',
      houseNumber: '25'
    },
    {
      id: 2,
      fullName: 'Another Person',
      name: 'Another',
      surname: 'Person',
      email: 'another@example.com',
      tel: '+380667007008',
      status: 'inactive',
      dateOfBirth: '01.01.1990',
      dateOfCreation: '01.01.2023',
      assistant: 'Some assistant',
      citizenship: 'Ukrainian',
      pesel: '0987654321',
      incubatorPaymentAccount: '11 22 333333 444 5555 66',
      passport: 'AB123456',
      clientPaymentAccount: '5555 4444 3333 22 1111',
      legalization: 'work permit',
      region: 'Lublin',
      city: 'Lublin',
      mailIndex: '20-001',
      street: 'Main Street',
      appartmentNumber: '15',
      houseNumber: '25'
    },
    {
      id: 3,
      fullName: 'John Doe',
      name: 'John',
      surname: 'Doe',
      email: 'john.doe@example.com',
      tel: '+1234567890',
      status: 'active',
      dateOfBirth: '15.08.1995',
      dateOfCreation: '05.03.2023',
      assistant: 'Jane Smith',
      citizenship: 'American',
      pesel: '5432109876',
      incubatorPaymentAccount: '99 88 777777 666 5555 44',
      passport: 'US123456',
      clientPaymentAccount: '7777 8888 9999 11 2222',
      legalization: 'work visa',
      region: 'California',
      city: 'Los Angeles',
      mailIndex: '90001',
      street: 'Sunset Blvd',
      appartmentNumber: '205',
      houseNumber: '25'
    },
    {
      id: 4,
      fullName: 'Maria Sanchez',
      name: 'Maria',
      surname: 'Sanchez',
      email: 'maria.sanchez@example.com',
      tel: '+9876543210',
      status: 'active',
      dateOfBirth: '28.11.1980',
      dateOfCreation: '10.07.2023',
      assistant: 'David Johnson',
      citizenship: 'Mexican',
      pesel: '1357924680',
      incubatorPaymentAccount: '12 34 567890 987 6543 21',
      passport: 'MX789012',
      clientPaymentAccount: '3333 4444 5555 66 7777',
      legalization: 'permanent residence',
      region: 'Jalisco',
      city: 'Guadalajara',
      mailIndex: '44100',
      street: 'Avenida Juarez',
      appartmentNumber: '102',
      houseNumber: '25'
    },
    {
      id: 5,
      fullName: 'Anna Petrova',
      name: 'Anna',
      surname: 'Petrova',
      email: 'anna.petrova@example.com',
      tel: '+79101234567',
      status: 'inactive',
      dateOfBirth: '03.09.1992',
      dateOfCreation: '19.02.2023',
      assistant: 'Ivan Ivanov',
      citizenship: 'Russian',
      pesel: '9876543210',
      incubatorPaymentAccount: '77 66 555555 444 3333 22',
      passport: 'RU456789',
      clientPaymentAccount: '9999 8888 7777 44 1111',
      legalization: 'student visa',
      region: 'Moscow Oblast',
      city: 'Moscow',
      mailIndex: '123456',
      street: 'Tverskaya Street',
      appartmentNumber: '5',
      houseNumber: '25'
    },
    {
      id: 6,
      fullName: 'Jane Smith',
      name: 'Jane',
      surname: 'Smith',
      email: 'jane.smith@example.com',
      tel: '+447700900123',
      status: 'active',
      dateOfBirth: '10.12.1988',
      dateOfCreation: '15.06.2023',
      assistant: 'John Doe',
      citizenship: 'British',
      pesel: '1029384756',
      incubatorPaymentAccount: '55 44 333333 222 1111 00',
      passport: 'UK987654',
      clientPaymentAccount: '6666 7777 8888 55 4444',
      legalization: 'work visa',
      region: 'London',
      city: 'London',
      mailIndex: 'SW1A 1AA',
      street: 'Buckingham Palace Road',
      appartmentNumber: '30',
      houseNumber: '25'
    },
    {
      id: 7,
      fullName: 'Carlos Gonzalez',
      name: 'Carlos',
      surname: 'Gonzalez',
      email: 'carlos.gonzalez@example.com',
      tel: '+34987654321',
      status: 'inactive',
      dateOfBirth: '20.05.1993',
      dateOfCreation: '25.11.2023',
      assistant: 'Marta Fernandez',
      citizenship: 'Spanish',
      pesel: '7465803921',
      incubatorPaymentAccount: '98 76 543210 123 4567 89',
      passport: 'ES123456',
      clientPaymentAccount: '5555 4444 3333 22 1111',
      legalization: 'permanent residence',
      region: 'Madrid',
      city: 'Madrid',
      mailIndex: '28001',
      street: 'Gran Via',
      appartmentNumber: '12',
      houseNumber: '25'
    },
    {
      id: 8,
      fullName: 'Andrei Popescu',
      name: 'Andrei',
      surname: 'Popescu',
      email: 'andrei.popescu@example.com',
      tel: '+40123456789',
      status: 'active',
      dateOfBirth: '12.03.1987',
      dateOfCreation: '30.10.2023',
      assistant: 'Mihai Radulescu',
      citizenship: 'Romanian',
      pesel: '1234576890',
      incubatorPaymentAccount: '11 22 333333 444 5555 66',
      passport: 'RO654321',
      clientPaymentAccount: '8888 9999 1111 22 3333',
      legalization: 'work visa',
      region: 'Bucharest',
      city: 'Bucharest',
      mailIndex: '010101',
      street: 'Victoriei Avenue',
      appartmentNumber: '8',
      houseNumber: '25'
    },
    {
      id: 9,
      fullName: 'Marta Kowalska',
      name: 'Marta',
      surname: 'Kowalska',
      email: 'marta.kowalska@example.com',
      tel: '+48123456789',
      status: 'active',
      dateOfBirth: '25.06.1990',
      dateOfCreation: '11.09.2023',
      assistant: 'Jan Nowak',
      citizenship: 'Polish',
      pesel: '5432109876',
      incubatorPaymentAccount: '22 33 456456 887 5445 66',
      passport: 'PL654321',
      clientPaymentAccount: '7777 8888 9999 11 2222',
      legalization: 'permanent residence',
      region: 'Masovian Voivodeship',
      city: 'Warsaw',
      mailIndex: '00-001',
      street: 'Nowy Swiat',
      appartmentNumber: '16',
      houseNumber: '25'
    },
    {
      id: 10,
      fullName: 'Luis Fernandez',
      name: 'Luis',
      surname: 'Fernandez',
      email: 'luis.fernandez@example.com',
      tel: '+34987654321',
      status: 'inactive',
      dateOfBirth: '07.08.1995',
      dateOfCreation: '28.12.2023',
      assistant: 'Elena Garcia',
      citizenship: 'Spanish',
      pesel: '1357924680',
      incubatorPaymentAccount: '77 66 555555 444 3333 22',
      passport: 'ES456789',
      clientPaymentAccount: '9999 8888 7777 44 1111',
      legalization: 'work visa',
      region: 'Barcelona',
      city: 'Barcelona',
      mailIndex: '08001',
      street: 'Rambla de Catalunya',
      appartmentNumber: '7',
      houseNumber: '25'
    },
    {
      id: 11,
      fullName: 'Sophie Martin',
      name: 'Sophie',
      surname: 'Martin',
      email: 'sophie.martin@example.com',
      tel: '+33123456789',
      status: 'active',
      dateOfBirth: '19.05.1988',
      dateOfCreation: '02.02.2023',
      assistant: 'Pierre Dupont',
      citizenship: 'French',
      pesel: '9081726354',
      incubatorPaymentAccount: '55 44 333333 222 1111 00',
      passport: 'FR123456',
      clientPaymentAccount: '6666 7777 8888 55 4444',
      legalization: 'work visa',
      region: 'Île-de-France',
      city: 'Paris',
      mailIndex: '75001',
      street: 'Champs-Élysées',
      appartmentNumber: '21',
      houseNumber: '25'
    },
    {
      id: 12,
      fullName: 'Giuseppe Rossi',
      name: 'Giuseppe',
      surname: 'Rossi',
      email: 'giuseppe.rossi@example.com',
      tel: '+390123456789',
      status: 'inactive',
      dateOfBirth: '11.10.1991',
      dateOfCreation: '20.04.2023',
      assistant: 'Mario Bianchi',
      citizenship: 'Italian',
      pesel: '3579124680',
      incubatorPaymentAccount: '98 76 543210 123 4567 89',
      passport: 'IT987654',
      clientPaymentAccount: '5555 4444 3333 22 1111',
      legalization: 'permanent residence',
      region: 'Lombardy',
      city: 'Milan',
      mailIndex: '20121',
      street: 'Via Montenapoleone',
      appartmentNumber: '4',
      houseNumber: '25'
    },
    {
      id: 13,
      fullName: 'Olga Petrovna',
      name: 'Olga',
      surname: 'Petrovna',
      email: 'olga.petrovna@example.com',
      tel: '+79123456789',
      status: 'active',
      dateOfBirth: '30.06.1994',
      dateOfCreation: '15.11.2023',
      assistant: 'Ivan Ivanov',
      citizenship: 'Russian',
      pesel: '7465803921',
      incubatorPaymentAccount: '77 66 555555 444 3333 22',
      passport: 'RU123456',
      clientPaymentAccount: '8888 9999 1111 22 3333',
      legalization: 'work visa',
      region: 'St. Petersburg',
      city: 'St. Petersburg',
      mailIndex: '190000',
      street: 'Nevsky Prospekt',
      appartmentNumber: '50',
      houseNumber: '25'
    },
    {
      id: 14,
      fullName: 'Hans Schmidt',
      name: 'Hans',
      surname: 'Schmidt',
      email: 'hans.schmidt@example.com',
      tel: '+49123456789',
      status: 'inactive',
      dateOfBirth: '03.04.1993',
      dateOfCreation: '30.10.2023',
      assistant: 'Marie Müller',
      citizenship: 'German',
      pesel: '1234576890',
      incubatorPaymentAccount: '11 22 333333 444 5555 66',
      passport: 'DE654321',
      clientPaymentAccount: '7777 8888 9999 11 2222',
      legalization: 'work visa',
      region: 'Berlin',
      city: 'Berlin',
      mailIndex: '10115',
      street: 'Unter den Linden',
      appartmentNumber: '9',
      houseNumber: '25'
    },
    {
      id: 15,
      fullName: 'Elena Sokolova',
      name: 'Elena',
      surname: 'Sokolova',
      email: 'elena.sokolova@example.com',
      tel: '+79123456789',
      status: 'active',
      dateOfBirth: '12.11.1989',
      dateOfCreation: '25.07.2023',
      assistant: 'Ivan Ivanov',
      citizenship: 'Russian',
      pesel: '9876543210',
      incubatorPaymentAccount: '77 66 555555 444 3333 22',
      passport: 'RU456789',
      clientPaymentAccount: '9999 8888 7777 44 1111',
      legalization: 'work visa',
      region: 'Moscow Oblast',
      city: 'Moscow',
      mailIndex: '123456',
      street: 'Tverskaya Street',
      appartmentNumber: '25',
      houseNumber: '25'
    },
    {
      id: 16,
      fullName: 'Paolo Bianchi',
      name: 'Paolo',
      surname: 'Bianchi',
      email: 'paolo.bianchi@example.com',
      tel: '+390123456789',
      status: 'inactive',
      dateOfBirth: '08.07.1992',
      dateOfCreation: '11.06.2023',
      assistant: 'Mario Rossi',
      citizenship: 'Italian',
      pesel: '1357924680',
      incubatorPaymentAccount: '98 76 543210 123 4567 89',
      passport: 'IT123456',
      clientPaymentAccount: '5555 4444 3333 22 1111',
      legalization: 'permanent residence',
      region: 'Tuscany',
      city: 'Florence',
      mailIndex: '50100',
      street: 'Piazza del Duomo',
      appartmentNumber: '6',
      houseNumber: '25'
    },
    {
      id: 17,
      fullName: 'Lucía Fernandez',
      name: 'Lucía',
      surname: 'Fernandez',
      email: 'lucia.fernandez@example.com',
      tel: '+34987654321',
      status: 'active',
      dateOfBirth: '02.09.1990',
      dateOfCreation: '03.03.2023',
      assistant: 'Carlos Gonzalez',
      citizenship: 'Spanish',
      pesel: '9081726354',
      incubatorPaymentAccount: '77 66 555555 444 3333 22',
      passport: 'ES987654',
      clientPaymentAccount: '6666 7777 8888 55 4444',
      legalization: 'work visa',
      region: 'Seville',
      city: 'Seville',
      mailIndex: '41001',
      street: 'Calle Sierpes',
      appartmentNumber: '14',
      houseNumber: '25'
    },
    {
      id: 18,
      fullName: 'Matteo Rossi',
      name: 'Matteo',
      surname: 'Rossi',
      email: 'matteo.rossi@example.com',
      tel: '+390123456789',
      status: 'inactive',
      dateOfBirth: '17.01.1991',
      dateOfCreation: '20.04.2023',
      assistant: 'Mario Bianchi',
      citizenship: 'Italian',
      pesel: '3579124680',
      incubatorPaymentAccount: '98 76 543210 123 4567 89',
      passport: 'IT987654',
      clientPaymentAccount: '5555 4444 3333 22 1111',
      legalization: 'permanent residence',
      region: 'Veneto',
      city: 'Venice',
      mailIndex: '30100',
      street: 'Piazza San Marco',
      appartmentNumber: '3',
      houseNumber: '25'
    },
    {
      id: 19,
      fullName: 'Anna Müller',
      name: 'Anna',
      surname: 'Müller',
      email: 'anna.muller@example.com',
      tel: '+49123456789',
      status: 'active',
      dateOfBirth: '05.06.1988',
      dateOfCreation: '30.10.2023',
      assistant: 'Hans Schmidt',
      citizenship: 'German',
      pesel: '1234576890',
      incubatorPaymentAccount: '11 22 333333 444 5555 66',
      passport: 'DE654321',
      clientPaymentAccount: '7777 8888 9999 11 2222',
      legalization: 'work visa',
      region: 'Hamburg',
      city: 'Hamburg',
      mailIndex: '20095',
      street: 'Mönckebergstraße',
      appartmentNumber: '11',
      houseNumber: '25'
    },
    {
      id: 20,
      fullName: 'Maria Popescu',
      name: 'Maria',
      surname: 'Popescu',
      email: 'maria.popescu@example.com',
      tel: '+40123456789',
      status: 'inactive',
      dateOfBirth: '09.02.1992',
      dateOfCreation: '20.04.2023',
      assistant: 'Mihai Radulescu',
      citizenship: 'Romanian',
      pesel: '5432109876',
      incubatorPaymentAccount: '22 33 456456 887 5445 66',
      passport: 'RO654321',
      clientPaymentAccount: '7777 8888 9999 11 2222',
      legalization: 'permanent residence',
      region: 'Cluj',
      city: 'Cluj-Napoca',
      mailIndex: '400000',
      street: 'Piata Unirii',
      appartmentNumber: '7',
      houseNumber: '25'
    }

  ];
}

function assistansData() {
  return [
    {
      id: 1,
      fullName: 'Слюсаренко Ассистент',
      name: 'Ассистент',
      surname: 'Слюсаренко',
      email: '1ddd@dd.dd',
      tel: '+380667007007',
      status: 'active',
      dateOfCreation: '20.04.2023',


    },
    {
      id: 2,
      fullName: 'Никитин Ассистент',
      name: 'Ассистент',
      surname: 'Никитин',
      email: '2ии@dd.dd',
      tel: '+380667002307',
      status: 'inactive',
      dateOfCreation: '18.04.2023',


    },
    {
      id: 3,
      fullName: 'Головко Ассистент',
      name: 'Ассистент',
      surname: 'Головко',
      email: '3ddd@dd.dd',
      tel: '+380967007007',
      status: 'active',
      dateOfCreation: '20.04.2023',


    },
    {
      id: 4,
      fullName: 'Мачмылин Ассистент',
      name: 'Ассистент',
      surname: 'Мачмылин',
      email: '4ddd@dd.dd',
      tel: '+380977007007',
      status: 'active',
      dateOfCreation: '05.10.2023',


    },
    {
      id: 5,
      fullName: 'Иванов Ассистент',
      name: 'Ассистент',
      surname: 'Иванов',
      email: '5ddd@dd.dd',
      tel: '+380667007007',
      status: 'active',
      dateOfCreation: '20.04.2023',


    },
    {
      id: 6,
      fullName: 'Петров Ассистент',
      name: 'Ассистент',
      surname: 'Петров',
      email: '6ии@dd.dd',
      tel: '+380667002307',
      status: 'inactive',
      dateOfCreation: '18.04.2023',


    },
    {
      id: 7,
      fullName: 'Головань Ассистент',
      name: 'Ассистент',
      surname: 'Головань',
      email: '7ddd@dd.dd',
      tel: '+380967007007',
      status: 'active',
      dateOfCreation: '20.04.2023',


    },
    {
      id: 8,
      fullName: 'Кипелов Ассистент',
      name: 'Ассистент',
      surname: 'Кипелов',
      email: '8ddd@dd.dd',
      tel: '+380977007007',
      status: 'active',
      dateOfCreation: '05.10.2023',

    },

  ];
}

function invoicesData() {
  return [
    {
      id:1,
      client: 'Slusarenko Ivan',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Christina Anderson',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: ['23%','8%'],
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id: 100,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"23%",
          vat:'7434.06',
          priceBrutto:'39756.06',

        },
        {
          id: 101,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"23%",
          vat:'7434.06',
          priceBrutto:'39756.06',

        },

      ],
      summaryNetto:64644,
      summaryVat:14868.12,
      summaryBrutto:79512.12
    },
    {
      id:2,
      client: 'Another Person',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Alex Johnson',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: ['23%','0%'],
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:200,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:202,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:3,
      client: 'John Doe',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Michael Smith',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:300,
          name:'ghhfgh',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:'23%',
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:303,
          name:'rrrr',
          pkwiu:'134',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:'23%',
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:4,
      client: 'Maria Sanchez',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'На рассмотрении',
      contractor:'Anna Kowalska',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: ['8%', '23%', '0% WDT'],
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:400,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:404,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:5,
      client: 'Anna Petrova',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Marcin Nowak',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:500,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:505,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:6,
      client: 'Jane Smith',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Ewa Wojciechowska',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:600,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:606,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:7,
      client: 'Carlos Gonzalez',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Andrzej Zieliński',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:700,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:32131,

        },
        {
          id:707,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:32131,

        },
        {
          id:700,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:707,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:8,
      client: 'Andrei Popescu',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Katarzyna Lewandowska',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:800,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:808,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:9,
      client: 'Marta Kowalska',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Piotr Szymański',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:909,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:1000,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:10,
      client: 'Luis Fernandez',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Magdalena Woźniak',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:10001,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:1100,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:11,
      client: 'Sophie Martin',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Grzegorz Krawczyk',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:1011,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:'23%',
          vat:'23%',
          priceBrutto:'32131',

        },
        {
          id:1111,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:'23%',
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id:12,
      client: 'Giuseppe Rossi',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'Одобрено',
      contractor:'Joanna Pawlak',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:1200,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:1220,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },
    {
      id: 13,
      client: 'Olga Petrovna',
      invoiceType: 'Фактура продажи',
      numberOfDocument: '1/06/23/FS/DR5/WR04',
      dateOfCreation: '23.04.2023',
      dateOfSale: '23.04.2023',
      status: 'in progress',
      contractor:'Wojciech Nowicki',
      origin:'Польша',
      name:'Kaminska T.Y.A',
      nip:'23719837912',
      mail:'asdgj@gmail.com',
      typeCompanyOr:'Компания',
      adress:'ul 3 maya 23',
      mailIndex:'78-567',
      city:'Gdansk',
      country:'Poland',
      transaction:'Заграничная',
      paymentMethod:'На банковский счет',
      paymentStatus:'Оплачено',
      paymentTerm:'28/12/06',
      currency:'USD',
      currencyCourse:'123.46',
      paymentAccount:'123 456 789 456 77777',
      invoiceComment:'asdlakshd ashdashdk asdh asjkhdkja ',
      internalComment:'asadhkadh jkhjkh jjkhkj jkhjkjkhkjjhk hhh jhkhjkhk',
      agent: 'Andrey Makieiev',
      sum: '5768',
      vat: '23%',
      billed: 'Chris Tacker',
      update: '11.05.2023',
      pdfLink: 'https://drive.google.com/file/d/1u5Nb49ipPZfitS54Cvkvf4rWodZgCHQY/view?usp=sharing',
      positionData:[
        {
          id:1300,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },
        {
          id:1330,
          name:'sdgfhj',
          pkwiu:'132324',
          quantity:'1',
          unit:'3',
          unitPriceNetto:'32322',
          priceNetto:'33333',
          vatRate:"ставка VAT",
          vat:'26%',
          priceBrutto:'32131',

        },

      ],
      summaryNetto:3213,
      summaryVat:1245,
      summaryBrutto:3134

    },

  ];
};

function contractorsData() {
  return [
    {
      id: 1,
      fullName: 'Christina Anderson',
      nip: '1234567890',
      adress: 'ul. Majowa 49A/97',
      mailIndex: '81-305',
      city: 'Gdańsk',
      country: 'Italy',
      mail: 'dsghja@fg.com',
      userWhoCreated: 'Robert Mitchell'
    },
    {
      id: 2,
      fullName: 'Alex Johnson',
      nip: '9876543210',
      adress: 'ul. Nowa 15B/3',
      mailIndex: '80-200',
      city: 'Gdynia',
      country: 'New zeland',
      mail: 'alex.johnson@email.com',
      userWhoCreated: 'Jessica Brown'
    },
    {
      id: 3,
      fullName: 'Michael Smith',
      nip: '5678901234',
      adress: 'ul. Kwiatowa 7',
      mailIndex: '90-001',
      city: 'Warszawa',
      country: 'Africa',
      mail: 'michael.smith@mailbox.com',
      userWhoCreated: 'Jennifer Davis'
    },
    {
      id: 4,
      fullName: 'Anna Kowalska',
      nip: '3456789012',
      adress: 'ul. Słoneczna 23/6',
      mailIndex: '61-123',
      city: 'Poznań',
      country: 'Australia',
      mail: 'anna.kowalska@examplemail.com',
      userWhoCreated: 'David Johnson'
    },
    {
      id: 5,
      fullName: 'Marcin Nowak',
      nip: '2109876543',
      adress: 'ul. Spacerowa 11',
      mailIndex: '02-456',
      city: 'Kraków',
      country: 'Mali',
      mail: 'marcin.nowak@mailprovider.com',
      userWhoCreated: 'Sarah Williams'
    },
    {
      id: 6,
      fullName: 'Ewa Wojciechowska',
      nip: '6789012345',
      adress: 'ul. Lipowa 8/15',
      mailIndex: '50-600',
      city: 'Wrocław',
      country: 'Usa',
      mail: 'ewa.wojciechowska@samplemail.com',
      userWhoCreated: 'John Smith'
    },
    {
      id: 7,
      fullName: 'Andrzej Zieliński',
      nip: '8901234567',
      adress: 'ul. Polna 3',
      mailIndex: '70-800',
      city: 'Szczecin',
      country: 'Japan',
      mail: 'andrzej.zielinski@testingmail.com',
      userWhoCreated: 'Mary Johnson'
    },
    {
      id: 8,
      fullName: 'Katarzyna Lewandowska',
      nip: '4567890123',
      adress: 'ul. Łąkowa 19C/2',
      mailIndex: '33-100',
      city: 'Tarnów',
      country: 'Hungary',
      mail: 'katarzyna.lewandowska@example.com',
      userWhoCreated: 'Daniel Martin'
    },
    {
      id: 9,
      fullName: 'Piotr Szymański',
      nip: '5432109876',
      adress: 'ul. Leśna 5',
      mailIndex: '99-200',
      city: 'Lublin',
      country: 'Germany',
      mail: 'piotr.szymanski@mailservice.com',
      userWhoCreated: 'Emily Taylor'
    },
    {
      id: 10,
      fullName: 'Magdalena Woźniak',
      nip: '7890123456',
      adress: 'ul. Ogrodowa 12/7',
      mailIndex: '44-300',
      city: 'Katowice',
      country: 'Polska',
      mail: 'magdalena.wozniak@samplemailprovider.com',
      userWhoCreated: 'Christopher Wilson'
    },
    {
      id: 11,
      fullName: 'Grzegorz Krawczyk',
      nip: '4321098765',
      adress: 'ul. Klonowa 2A',
      mailIndex: '22-500',
      city: 'Białystok',
      country: 'Białystok',
      mail: 'grzegorz.krawczyk@email.com',
      userWhoCreated: 'Melissa Martinez'
    },
    {
      id: 12,
      fullName: 'Joanna Pawlak',
      nip: '6789012345',
      adress: 'ul. Zamkowa 7',
      mailIndex: '77-400',
      city: 'Gliwice',
      country: 'Gliwice',
      mail: 'joanna.pawlak@providermail.com',
      userWhoCreated: 'Kevin Thompson'
    },
    {
      id: 13,
      fullName: 'Wojciech Nowicki',
      nip: '5432109876',
      adress: 'ul. Morska 9',
      mailIndex: '88-600',
      city: 'Bydgoszcz',
      country: 'Bydgoszcz',
      mail: 'wojciech.nowicki@mailaddress.com',
      userWhoCreated: 'Laura White'
    },
    {
      id: 14,
      fullName: 'Monika Kwiatkowska',
      nip: '7890123456',
      adress: 'ul. Plażowa 3/5',
      mailIndex: '55-700',
      city: 'Olsztyn',
      country: 'Польша',
      mail: 'monika.kwiatkowska@testingmail.com',
      userWhoCreated: 'Mark Davis'
    },
    {
      id: 15,
      fullName: 'Tomasz Jankowski',
      nip: '4321098765',
      adress: 'ul. Szkolna 1',
      mailIndex: '66-800',
      city: 'Płock',
      country: 'Uruguai',
      mail: 'tomasz.jankowski@emailprovider.com',
      userWhoCreated: 'Natalie Brown'
    }
  ]
};

export {
  adminNavData,
  userData,
  indicatorsKPI,
  administrationData,
  clientsData,
  invoicesData,
  contractorsData,
  assistansData
};

//const arrays for choice in invoices pages
const vatData = ['23%', '8%', '5%', '0%', 'zw.', 'np.', 'np.EU', '0% WDT', '0%exp.']
const currencies = [
  {
      value: 'USD',
      label: 'USD',
  },
  {
      value: 'EUR',
      label: 'EUR',
  },
  {
      value: 'PLN',
      label: 'PLN',
  },
  {
      value: 'GBP',
      label: 'GBP',
  },
  {
      value: 'CHF',
      label: 'CHF',
  },
];

const countryData = [
  {
      id: 'poland',
      value: 'Польша'
  },
  {
      id: 'eu',
      value: 'ЕС'
  },
  {
      id: 'otherWorld',
      value: 'Остальной мир'
  },
];
const companyData = [
  {
      id: 'company',
      value: 'Компания'
  },
  {
      id: 'individual',
      value: 'Физическое лицо'
  },
];
const transactionData = [
  {
      id: 'local',
      value: 'Локальная'
  },
  {
      id: 'overseas',
      value: 'Заграничная'
  }
];

const paymentMethod = [
  {
      id: 'paymentMethod',
      value: 'На банковский счет'
  }
];

const paymentStatus = [
  {
      id: 'paymentStatusOk',
      value: 'Оплачено'
  },
  {
      id: 'paymentStatusWait',
      value: 'Не оплачено'
  }
];
const statusData = [
  {
      id: 'onReview',
      value: 'На рассмотрении'
  },
  {
      id: 'approved',
      value: 'Одобрено'
  }
];