import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useState, useMemo } from "react";
import { Typography, TextField } from '@mui/material';
import { blueGrey, orange } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import ActionEditContractor from '../admin-modal-components/modal-pages-for-option-administration/ActionEditContractor';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];
const gray100 = blueGrey[100];


function createData(id, fullName, nip, adress, city, country, userWhoCreated, actions) {
    return {
        id, // Добавляем id
        fullName, nip, adress, city, country, userWhoCreated, actions
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'fullName',
        numeric: false,
        disablePadding: true,
        label: 'Контрагент',
    },
    {
        id: 'nip',
        numeric: true,
        disablePadding: false,
        label: 'NIP',
    },
    {
        id: 'adress',
        numeric: true,
        disablePadding: false,
        label: 'Адрес',
    },
    {
        id: 'city',
        numeric: true,
        disablePadding: false,
        label: 'Город',
    },
    {
        id: 'country',
        numeric: true,
        disablePadding: false,
        label: 'Страна',
    },
    {
        id: 'dateOfCreation',
        numeric: true,
        disablePadding: false,
        label: 'Добавлен',
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Действия',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            sx={{ height: '50px', width: '100px' }}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function OptionContractors(props) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [clickedRowId, setClickedRowId] = useState(null); // Состояние для id выбранной строки

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = props.contractorsData().map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => { // Получаем id выбранной строки
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);

        // Сохраняем id выбранной строки
        setClickedRowId(id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const invoicesDataItem = props.contractorsData().map((item) => (
        createData(
            item.id, // Вот сюда
            item.fullName,
            item.nip,
            item.adress,
            item.city,
            item.country,
            item.userWhoCreated,
            item.actions
        )
    ));

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.contractorsData().length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(invoicesDataItem, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const boxSX = {
        background: gray,
        width: '250px',
        height: '42px',
        "&:hover": {
            border: "1px solid #000",
            color: '#ffff',
            backgroundColor: grayLight
        },
    };

    const inputStyles = {
        width: 400,
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayLight,
            },
            '&.Mui-focused fieldset': {
                borderColor: gray,
            },
            underline: {
                '&::placeholder': {
                    color: 'red',
                    opacity: 1,
                }
            },
        },
        '& label.Mui-focused': {
            color: grayLightGhost,
        },
    };

    return (
        <Box sx={{ padding: 0, margin: 0 }}>
            <Box sx={{ maxWidth: '1400px', margin: '0 auto', position: 'sticky', top: '17px', zIndex: '4' }}>
                <Box sx={{
                    width: '100%',
                    zIndex: 4,
                    top: '64px',
                    padding: '66px 0px 30px 0px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#fff',
                    borderBottom: `1px solid ${gray100}`
                }}>

                    <Typography variant='h4'>Контрагенты</Typography>
                    <TextField type="search" id="search" label="Поиск"
                        sx={inputStyles}
                        inputProps={{
                            style: {
                                height: "11px",
                                '& .MuiInputInput::placeholder': {
                                    color: 'gray',
                                }
                            },
                        }}
                    />
                    <Box sx={{
                        maxWidth: '530px',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Link to='/admin/contractors/create'><Button sx={boxSX} variant="contained">+ ДОБАВИТЬ КОНТРАГЕНТА</Button></Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                maxWidth: '1380px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                margin: '0 auto',
            }}>
                <Box sx={{
                    width: '100%',
                    mb: 2,
                    paddingTop: '40px',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                }}>
                    <TableContainer sx={{ height: '600px' }}>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={props.contractorsData().length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)} // Передаем id строки
                                            
                                            tabIndex={-1}
                                            key={index}
                                            
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell
                                                id={labelId}
                                                align="left"
                                            >
                                                {row.fullName}
                                            </TableCell>
                                            <TableCell align="left">{row.nip}</TableCell>
                                            <TableCell align="left">{row.adress}</TableCell>
                                            <TableCell align="left">{row.city}</TableCell>
                                            <TableCell align="left">{row.country}</TableCell>
                                            <TableCell align="left">{row.userWhoCreated}</TableCell>
                                            <TableCell align="left">
                                                <ActionEditContractor
                                                    rowId={clickedRowId}
                                                    name={row.fullName}
                                                    clientsData={props.clientsData}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.contractorsData().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ marginRight: '550px' }}
                    />
                </Box>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Убрать отступы"
                />
            </Box>
        </Box >
    );
}