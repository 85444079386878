import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert } from "@mui/material";
import { useState } from "react";
import PopUp from "../../../modal/PopUp";
import ActionAssignContractorsFormForInvoice from "../../admin-modal-components/modal-pages-for-option-administration/ActionAssignContractorsFormForInvoice";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    color: '#fff',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    width: '100%',
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};
const buttonSX = {
    width: '100%',
    fontSize: '11px',
    color: grayLight,
    display: 'flex',
    justifyContent: 'flex-start',
    height: '20px',
    "&:hover": {
        backgroundColor: 'inherit',
        color: gray
    },
}
const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;



export const ContractosInputsBlock = (props) => {

    const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);

    return (
        <>
            <Box sx={{
                maxWidth: '1400px', margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '48px 0px 20px 0px',
                alignItems: 'center'
            }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Данные контрагента</Typography>
                <PopUp sx={boxSX}
                    name={"+ Добавить контрагента"}
                    container={
                        <ActionAssignContractorsFormForInvoice
                            header={"Добавить контрагента"}
                            handleClose={handleCloseAddClient}
                            data={props.contractorsData}
                            countryData={props.countryData}
                            currentClient={props.currentClient}
                            companyData={props.companyData}
                        />
                    }
                    open={openAddClient}
                    handleOpen={handleOpenAddClient}
                    handleClose={handleCloseAddClient}
                    width={'70%'}
                />
            </Box>
            <Grid container spacing={1} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                {props.alertMessage}
                <Box sx={boxData}>
                    <Box sx={{ width: '48%' }}>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Контрагент</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"    //contractorsData
                                    id="demo-simple-select"
                                    label={"Контрагент"}
                                    value={props.currentContractors}
                                    onChange={props.handleChangeContractors}
                                >
                                    {props.contractorsData().map((option) => (
                                        <MenuItem key={option.id} value={option.fullName}>
                                            {option.fullName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Происхождение</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"    //countryData
                                    label={"Происхождение"}
                                    value={props.currentCountry}
                                    onChange={props.handleChangeCountry}
                                >
                                    {props.countryData.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}//////////////////////////////////////////////////////////
                                fullWidth
                                id="name"
                                label="название"
                                variant="outlined"
                                type="text"
                                value={props.currentNameForContractorsBlock}

                            />
                        </Box>
                        {props.currentCompanyType === 'Компания' && (
                            < Box sx={{ paddingTop: '20px' }}>
                                <TextField size="small" sx={inputStyles}
                                    fullWidth
                                    id="nip"
                                    label="NIP"
                                    variant="outlined"
                                    type="text"
                                    value={props.currentNip}
                                />
                            </Box>)
                        }
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="email"
                                label="Email"
                                variant="outlined"
                                type="text"
                                value={props.mail}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '48%' }}>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={"Тип"}
                                    value={props.currentCompanyType}
                                    onChange={props.handleChangeCompanyType}
                                >
                                    {props.companyData.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="adress"
                                label="Адрес"
                                variant="outlined"
                                type="text"
                                value={props.currentAdress}
                            />
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="mailIndex"
                                label="Почтовый индекс"
                                variant="outlined"
                                type="text"
                                value={props.currentMailIndex}
                            />
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="city"
                                label="Город"
                                variant="outlined"
                                type="text"
                                value={props.currentCity}

                            />
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="country"
                                label="Страна"
                                variant="outlined"
                                type="text"
                                value={props.currentCountryOfCompany}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid >
        </>
    )

}