import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { Login } from '../../components/login-component/Login';
import OptionMain from "../../components/admin-page-components/admin-page-shells/OptionMain";


function MainAdmin(props) {

    return (
        <>
            <SideNav
                getUserInfo={props.getUserInfo}
                navData={props.navData}
                userData={props.userData}
                handleLogout={props.handleLogout}
                login={props.login} container={
                    <OptionMain

                        indicatorsKPI={props.indicatorsKPI}

                    />} />
        </>
    );

}
export default MainAdmin;
