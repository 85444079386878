import React from "react";
import { Login } from '../../components/login-component/Login';
import { LoginRestore } from '../../components/login-restore-component/LoginRestore';
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import MainAdmin from "../main-pages/MainAdmin";
import { RestorePass } from "../../components/password-restore-component/RestorePass";
import Cookies from 'js-cookie';
import axios from "axios";
import {
  adminNavData,
  indicatorsKPI,
  administrationData,
  clientsData,
  invoicesData,
  contractorsData,
  assistansData
} from "../../data/data";

import Account from "../account/Account";
import Administration from "../admin-pages/Administration";
import Clients from '../admin-pages/Clients';
import Invoices from "../admin-pages/Invoices";
import Workers from "../admin-pages/Workers";
import Contractors from "../admin-pages/Contractors";
import AdminNewUser from "../admin-pages/AdminNewUser";
import ClientDetails from "../admin-pages/ClientDetails";
import ClientDetailsEdit from "../admin-pages/ClientDetailsEdit";
import { CreateClient } from "../admin-pages/CreateClient";
import InvoicesCreate from "../admin-pages/InvoicesCreate";
import InvoicesInfo from "../admin-pages/InvoicesInfo";
import InvoicesDetailsEdit from "../admin-pages/InvoicesDetailsEdit";
import { CreateContractor } from "../admin-pages/CreateContractor";
import { useNavigate } from 'react-router-dom';

function App() {

  const [isLogined, setIsLogined] = useState(false);

  const token = Cookies.get('token');
  useEffect(() => {
    if (token) {
      setIsLogined(true);
    }
  }, [token]);

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
    setIsLogined(false)
  }
  const [newPass, setNetPass] = useState(false);

  const [userData, setUserData] = useState(''); // object with user data
  const userDataHandler = (value) => {           //getting userData in login component
    setUserData(value);
    console.log(value)
  }

  const getUserInfo = () => {
    try {
      axios.post(`https://crm.weexpert.io/api/validate_token.php`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        const responseData = response.data
        console.log('update')
        userDataHandler(responseData);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [rights, setRights] = useState(false);
  const currentRoleHandler = (value) => {    // get current role
    setRights(value);
    console.log(value)
  }

  const handlNewPass = () => {
    setNetPass(!newPass)
  }


  const handleLogin = () => {
    setIsLogined(!isLogined)
  }


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <React.Fragment>
            {/*{
              !isLogined && (
                <Login
                  login={handleLogin}
                  userDataHandler={userDataHandler}
                  currentRoleHandler={currentRoleHandler}
                />
              )
            }*/}
            {
              (isLogined) ? (
                <MainAdmin
                getUserInfo={getUserInfo}
                  handleLogout={handleLogout}
                  navData={adminNavData}
                  userData={userData}
                  login={handleLogin}
                  indicatorsKPI={indicatorsKPI}
                />
              ) : (<Login
                handleLogout={handleLogout}
                login={handleLogin}
                userDataHandler={userDataHandler}
                currentRoleHandler={currentRoleHandler} />)
            }
          </React.Fragment>
        } />

        <Route path="/restore" element={<LoginRestore userData={userData} />} />

        <Route path="/restore/newpass" element={<RestorePass />} />

        <Route path="account" element={
          <React.Fragment>
            {(isLogined) ? (
              <Account
              getUserInfo={getUserInfo}
                handleLogout={handleLogout}
                userDataHandler={userDataHandler}
                rights={rights}
                navData={adminNavData}
                userData={userData}
                handlNewPass={handlNewPass}
                newPass={newPass}
              />) : (<Login
                login={handleLogin}
                userDataHandler={userDataHandler}
                currentRoleHandler={currentRoleHandler} />)
            }
          </React.Fragment>
        } />

        <Route path='admin/administration' element={
          <React.Fragment>
            {(isLogined) ? (
              <Administration
              getUserInfo={getUserInfo}
                handleLogout={handleLogout}
                navData={adminNavData}
                userData={userData}
                administrationData={administrationData}
                clientsData={clientsData}
              />) : (<Login
                login={handleLogin}
                userDataHandler={userDataHandler}
                currentRoleHandler={currentRoleHandler} />)
            }
          </React.Fragment>
        } />


        <Route path='admin/clients' element={
          <React.Fragment>
            {(isLogined) ? (<Clients
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              clientsData={clientsData}
              assistansData={assistansData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/create/client' element={
          <React.Fragment>
            {(isLogined) ? (<CreateClient
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
            />
            ) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/client/details/:id' element={
          <React.Fragment>
            {(isLogined) ? (<ClientDetails
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              clientsData={clientsData}
              navData={adminNavData}
              userData={userData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/client/details/edit/:id' element={
          <React.Fragment>
            {(isLogined) ? (<ClientDetailsEdit
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              clientsData={clientsData}
              navData={adminNavData}
              userData={userData}
              assistansData={assistansData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/invoices' element={
          <React.Fragment>
            {(isLogined) ? (<Invoices
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              invoicesData={invoicesData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/invoices/create' element={
          <React.Fragment>
            {(isLogined) ? (<InvoicesCreate
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              clientsData={clientsData}
              contractorsData={contractorsData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/invoices/details/:id' element={
          <React.Fragment>
            {(isLogined) ? (<InvoicesInfo
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              invoicesData={invoicesData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/invoices/details/edit/:id' element={
          <React.Fragment>
            {(isLogined) ? (<InvoicesDetailsEdit
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              clientsData={clientsData}
              contractorsData={contractorsData}
              invoicesData={invoicesData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/workers' element={
          <React.Fragment>
            {(isLogined) ? (<Workers
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              invoicesData={invoicesData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/contractors' element={
          <React.Fragment>
            {(isLogined) ? (<Contractors
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              contractorsData={contractorsData}
              clientsData={clientsData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/contractors/create' element={
          <React.Fragment>
            {(isLogined) ? (<CreateContractor
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              clientsData={clientsData}
              contractorsData={contractorsData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />

        <Route path='admin/create/user' element={
          <React.Fragment>
            {(isLogined) ? (<AdminNewUser
            getUserInfo={getUserInfo}
              handleLogout={handleLogout}
              navData={adminNavData}
              userData={userData}
              clientsData={clientsData}
            />) : (<Login
              login={handleLogin}
              userDataHandler={userDataHandler}
              currentRoleHandler={currentRoleHandler} />)}
          </React.Fragment>
        } />
        <Route path='*' element={'404'} />

      </Routes>
    </div>
  );
}

export default App;
