import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountInfo from './AccountInfo';
import { blueGrey } from "@mui/material/colors";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const grayLightGhost = blueGrey[300];

const inputStyles = {
  margin: '10px 0px 10px 0px',
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: gray,
    },
    underline: {
      '&::placeholder': {
        color: 'red',
        opacity: 1,
      }
    },
  },
  '& label.Mui-focused': {
    color: grayLightGhost,
  },
};

const boxSX = {
  background: gray,
  width: '40%',
  height: '42px',
  "&:hover": {
    border: "1px solid #000",
    color: '#ffff',
    backgroundColor: grayLight
  },
};
const boxSXWhite = {
  border: `1px solid ${gray}`,
  width: '40%',
  height: 42,
  margin: '0px 0px 0px 30px',
  color: gray,
  "&:hover": {
    border: `1px solid ${grayLight}`,
    color: gray,
    background: grayGhost
  },
}
const boxMessage = {
  padding: '0px 0px 32px 0px'
};

const AccountForm = (props) => {
  const token = Cookies.get('token');
  const [alertMessage, setAlertMessage] = useState('');

  const [inputValueName, setInputValueName] = useState('');
  const [rights, setRights] = useState('')
  const [inputValueSurname, setInputValueSurname] = useState('');
  const [mail, setMail] = useState('');
  const [tel, setTel] = useState('');



  const alertMessagehandler = (value) => {
    setAlertMessage(value)
  }


  const getUserInfo = async () => {
    try {
      const response = await axios.post(
        'https://crm.weexpert.io/api/validate_token.php',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const user = response.data;
      console.log("Данные по ползователю получил и распарсил в стейт")
      setRights(user.role);
      setInputValueName(user.firstname);
      setInputValueSurname(user.lastname);
      setMail(user.email);
      setTel(user.tel);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getUserInfo();
  }, [token]);


  const handleCleanValueName = () => {
    setInputValueName('');
  };

  const handleCleanValueSurname = () => {
    setInputValueSurname('');
  };

  const handleCleanValueTel = () => {
    setTel('');
  };

  const handleCleanValueMail = () => {
    setMail('');
  };

  const cleanValues = () => {
    setInputValueName('');
    setInputValueSurname('');
    setMail('');
    setTel('');
  };

  const handleInputNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z]+$/;
    const isValid = regex.test(value);

    setInputValueName(value);
    if (!isValid && value.length !== 0) {
      setAlertMessage(alertErrorIncorrectText);
    } else {
      setAlertMessage('');
    }
  };

  const handleInputSurnameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z]+$/;
    const isValid = regex.test(value);

    setInputValueSurname(value);
    if (!isValid && value.length !== 0) {
      setAlertMessage(alertErrorIncorrectText);
    } else {
      setAlertMessage('');
    }
  };

  const handleInputTelChange = (event) => {
    const value = event.target.value;
    setTel(value);
  };

  const setMailHandler = (event) => {
    const value = event.target.value;
    setMail(value);
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!re.test(String(value).toLocaleLowerCase()) && value.length !== 0) {
      setAlertMessage(alertErrorMail);
    } else {
      setAlertMessage('');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage('');
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [alertMessage]);

 

  const alertErrorIncorrectText = (
    <Alert severity="error">Только латинские буквы</Alert>
  );
  const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;
  const alertSuccess = <Alert severity="success">Данные успешно изменены</Alert>;
  const alertErrorAsses = <Alert severity="error">Попытка неуспешна</Alert>;


  const onClickSendButtonHandler = () => {
    try {
      axios.post(`https://crm.weexpert.io/api/update_user.php`,
        {
          edit_id: rights,
          firstname: inputValueName,
          lastname: inputValueSurname,
          tel: tel,
          email: mail,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
          const user = response.data;
          Cookies.set('token', `${user.jwt}`, { expires: 1 });
          console.log(user)
          props.getUserInfo();
          //props.userDataHandler(responseData);
          //if(responseData.success === false){
          //   alertMessagehandler(alertErrorAsses)
          //}
          //else{
          //  alertMessagehandler(alertSuccess);
          // }
          alertMessagehandler(alertSuccess);
          cleanValues();
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container spacing={1} direction={'column'}>
      <Typography variant="h4" sx={{ padding: '30px 0px 30px 5px' }}>
        Мой аккаунт
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 0px 24px 0px',
          maxWidth: '552px',
        }}
      >
        <AccountCircleIcon
          sx={{
            width: 50,
            height: 50,
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            padding: '0px 0px 0px 20px',
          }}
        >
          {/* Передаем userData в AccountInfo */}
          <AccountInfo userData={props.userData} />
        </Box>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        method="post"
        onSubmit={(e) => e.preventDefault()}
        sx={{ maxWidth: '552px' }}
      >
        <Box sx={boxMessage}>{alertMessage}</Box>
        <TextField
          sx={inputStyles}
          fullWidth
          id="name"
          label="Имя"
          variant="outlined"
          type="text"
          value={inputValueName}
          onClick={handleCleanValueName}
          onChange={handleInputNameChange}
        />
        <TextField
          sx={inputStyles}
          fullWidth
          id="surname"
          label="Фамилия"
          variant="outlined"
          type="text"
          value={inputValueSurname}
          onClick={handleCleanValueSurname}
          onChange={handleInputSurnameChange}
        />
        <TextField
          sx={inputStyles}
          fullWidth
          id="tel"
          label="Номер телефона"
          variant="outlined"
          type="tel"
          value={tel}
          onClick={handleCleanValueTel}
          onChange={handleInputTelChange}
        />
        <TextField
          sx={inputStyles}
          fullWidth
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          value={mail}
          onClick={handleCleanValueMail}
          onChange={setMailHandler}
        />
        <Box
          sx={{
            display: 'flex',
            padding: '20px 0px 0px 0px',
          }}
        >
          <Button
            sx={boxSX}
            variant="contained"
            type="submit"
            onClick={() => {
              onClickSendButtonHandler();
            }}
          >
            Сохранить
          </Button>
          <Button
            sx={boxSXWhite}
            variant="outlined"
            onClick={props.handlNewPass}
          >
            Сменить пароль
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default AccountForm;


