import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionInvoicesDetailsEdit } from "../../components/admin-page-components/admin-page-shells/OptionInvoicesDetailsEdit";


const InvoicesDetailsEdit = (props) => {
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            handleLogout={props.handleLogout}
            login={props.login} container={<OptionInvoicesDetailsEdit
                clientsData={props.clientsData}
                contractorsData={props.contractorsData}
                invoicesData={props.invoicesData}
            />} />
    )

}
export default InvoicesDetailsEdit;