import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import { ActionWithClientInDetails } from "../administration-tables-controls-components/ActionWithClientInDetails";
import { Alert } from "@mui/material";
import { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const OptionClientDetailsEdit = (props) => {

    const { id } = useParams();

    const clientItem = (data, id) => {
        let client;
        data.forEach((item) => {
            if (item.id == id) {
                client = item;
            }
        });
        return client;
    };

    const itemData = clientItem(props.clientsData(), id);  //get the desired object;

    const [alertMessage, setAllertMessage] = useState('');
    /* states for inputs */
    const [inputValueName, setInputValueName] = useState(itemData ? itemData.name : 'N/A');
    const handleInputNameChange = (event) => {      //name
        const newValue = event.target.value;
        const regex = /^[a-zA-Z]+$/;
        const isValid = regex.test(newValue);
        setInputValueName(newValue)
        if (!isValid && newValue.length !== 0) {
            setAllertMessage(alertErrorIncorrectText)
        }
        else {
            setAllertMessage('')
        }
        console.log(inputValueName)
    };
    const handleCleanValueName = () => {
        setInputValueName('')
    }

    const [mail, setMail] = useState(itemData ? itemData.email : 'N/A'); //email
    const setMailHandler = (event) => {
        const newValue = event.target.value;
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const isValid = re.test(newValue);
        setMail(newValue)
        if (!isValid && newValue.length !== 0) {
            setAllertMessage(alertErrorMail)
        } else
            setAllertMessage('')
    };
    const handleCleanValueMail = () => {
        setMail('')
    }

    const [inputValueCitizenship, setInputValueCitizenship] = useState(itemData ? itemData.citizenship : 'N/A');
    const handleInputCitizenshipChange = (event) => {      //citizenship
        const newValue = event.target.value;
        setInputValueCitizenship(newValue);
        console.log(newValue);
    };
    const handleCleanValueCitizenship = () => {
        setInputValueCitizenship('')
    }

    const [inputValuePesel, setInputValuePesel] = useState(itemData ? itemData.pesel : 'N/A');
    const handleInputPeselChange = (event) => {      //pesel
        const newValue = event.target.value;
        setInputValuePesel(newValue);
        console.log(newValue);
    };
    const handleCleanValuePesel = () => {
        setInputValuePesel('')
    }

    const [inputValueIncubatorPaymentAccount, setInputValueIncubatorPaymentAccount] = useState(itemData ? itemData.incubatorPaymentAccount : 'N/A');
    const handleInputIncubatorPaymentAccountChange = (event) => {      //IncubatorPaymentAccount
        const newValue = event.target.value;
        setInputValueIncubatorPaymentAccount(newValue);
        console.log(newValue);
    };
    const handleCleanValueIncubatorPaymentAccount = () => {
        setInputValueIncubatorPaymentAccount('')
    }

    const [inputValueSurname, setInputValueSurname] = useState(itemData ? itemData.surname : 'N/A');
    const handleInputSurnameChange = (event) => {      //surname
        const newValue = event.target.value;
        setInputValueSurname(newValue);
        console.log(newValue);
    };
    const handleCleanValueSurname = () => {
        setInputValueSurname('')
    }

    const [inputValueTel, setInputValueTel] = useState(itemData ? itemData.tel : 'N/A');
    const handleInputTelChange = (event) => {      //tel
        const newValue = event.target.value;
        setInputValueTel(newValue);
        console.log(newValue);
    };
    const handleCleanValueTel = () => {
        setInputValueTel('')
    }

    const [inputValueDateOfBirth, setInputValueDateOfBirth] = useState(itemData ? itemData.dateOfBirth : 'N/A');
    const handleInputDateOfBirthChange = (event) => {      //dateOfBirth
        const newValue = event.target.value;
        setInputValueDateOfBirth(newValue);
        console.log(newValue);
    };
    const handleCleanValueDateOfBirth = () => {
        setInputValueDateOfBirth('')
    }

    const [inputValuePassport, setInputValuePassport] = useState(itemData ? itemData.passport : 'N/A');
    const handleInputPassportChange = (event) => {      //passport
        const newValue = event.target.value;
        setInputValuePassport(newValue);
        console.log(newValue);
    };
    const handleCleanValuePassport = () => {
        setInputValuePassport('')
    }

    const [inputValueClientPaymentAccount, setInputValueClientPaymentAccount] = useState(itemData ? itemData.clientPaymentAccount : 'N/A');
    const handleInputClientPaymentAccountChange = (event) => {      //ClientPaymentAccount
        const newValue = event.target.value;
        setInputValueClientPaymentAccount(newValue);
        console.log(newValue);
    };
    const handleCleanValueClientPaymentAccount = () => {
        setInputValueClientPaymentAccount('')
    }

    const [inputValueStatus, setInputValueStatus] = useState(itemData ? itemData.status : 'N/A');
    const handleInputStatusChange = (event) => {      //status
        const newValue = event.target.value;
        setInputValueStatus(newValue);
        console.log(newValue);
    };
    const handleCleanValueStatus = () => {
        setInputValueStatus('')
    }

    const [inputValueDateOfCreation, setInputValueDateOfCreation] = useState(itemData ? itemData.dateOfCreation : 'N/A');
    const handleInputDateOfCreationChange = (event) => {      //dateOfCreation
        const newValue = event.target.value;
        setInputValueDateOfCreation(newValue);
        console.log(newValue);
    };
    const handleCleanValueDateOfCreation = () => {
        setInputValueDateOfCreation('')
    }

    const [inputValueRegion, setInputValueRegion] = useState(itemData ? itemData.region : 'N/A');
    const handleInputRegionChange = (event) => {      //region
        const newValue = event.target.value;
        setInputValueRegion(newValue);
        console.log(newValue);
    };
    const handleCleanValueRegion = () => {
        setInputValueRegion('')
    }

    const [inputValueMailIndex, setInputValueMailIndex] = useState(itemData ? itemData.mailIndex : 'N/A');
    const handleInputMailIndexChange = (event) => {   //mailIndex 
        const newValue = event.target.value;
        setInputValueMailIndex(newValue);
        console.log(newValue);
    };
    const handleCleanValueMailIndex = () => {
        setInputValueMailIndex('')
    }

    const [inputValueHouseNumber, setInputValueHouseNumber] = useState(itemData ? itemData.houseNumber : 'N/A');
    const handleInputHouseNumberChange = (event) => {   //houseNumber
        const newValue = event.target.value;
        setInputValueHouseNumber(newValue);
        console.log(newValue);
    };
    const handleCleanValueHouseNumber = () => {
        setInputValueHouseNumber('')
    }

    const [inputValueCity, setInputValueCity] = useState(itemData ? itemData.city : 'N/A');
    const handleInputCityChange = (event) => {   //city
        const newValue = event.target.value;
        setInputValueCity(newValue);
        console.log(newValue);
    };
    const handleCleanValueCity = () => {
        setInputValueCity('')
    }

    const [inputValueStreet, setInputValueStreet] = useState(itemData ? itemData.street : 'N/A');
    const handleInputStreetChange = (event) => {   //street
        const newValue = event.target.value;
        setInputValueStreet(newValue);
        console.log(newValue);
    };
    const handleCleanValueStreet = () => {
        setInputValueStreet('')
    }

    const [inputValueAppartmentNumber, setInputValueAppartmentNumber] = useState(itemData ? itemData.appartmentNumber : 'N/A');
    const handleInputAppartmentNumberChange = (event) => {  //appartmentNumber
        const newValue = event.target.value;
        setInputValueAppartmentNumber(newValue);
        console.log(newValue);
    };
    const handleCleanValueAppartmentNumber = () => {
        setInputValueAppartmentNumber('')
    }

    const [currentAssistant, setCurrentAssistant] = useState('');

    const handleChangeAssistant = (event) => {   //get id assisant
        setCurrentAssistant(event.target.value);
    };

    const alertErrorIncorrectText = <Alert severity="error">Только латинские буквы</Alert>;
    const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;

    const assistantItem = props.assistansData().map((item, index) => (
        <MenuItem key={uuidv4()} value={item.id}>{item.fullName}</MenuItem>
    ))

    return (
        <Box sx={{
            maxWidth: '1400px', margin: '0 auto'
        }} >
            <Typography sx={{ padding: '30px 0px 30px 0px', maxWidth: '100%' }} variant='h4'>Детали клиента</Typography>
            <Box sx={{
                maxWidth: '1400px', margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '30px 0px 30px 0px'
            }}>
                <Box>
                    <Typography variant='h7'>Клиент</Typography>
                    <Typography variant='h5'>{itemData ? itemData.fullName : 'N/A'}</Typography>
                </Box>
                <Button sx={boxSX} variant="contained"> Сохранить</Button>
            </Box>
            <Grid container spacing={1} justifyContent={'space-between'} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Основные информация</Typography>
                    <Box>{alertMessage}</Box>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '20px' }}>

                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="name"
                                    label="Имя"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueName}
                                    onChange={handleInputNameChange}
                                    onClick={handleCleanValueName}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="mail"
                                    label="Email"
                                    variant="outlined"
                                    type="text"
                                    value={mail}
                                    onChange={setMailHandler}
                                    onClick={handleCleanValueMail}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="citizenship"
                                    label="Гражданство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueCitizenship}
                                    onChange={handleInputCitizenshipChange}
                                    onClick={handleCleanValueCitizenship}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="pesel"
                                    label="PESEL"
                                    variant="outlined"
                                    type="text"
                                    value={inputValuePesel}
                                    onChange={handleInputPeselChange}
                                    onClick={handleCleanValuePesel}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="incubatorPaymentAccount"
                                    label="Банковский счёт в инкубаторе"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueIncubatorPaymentAccount}
                                    onChange={handleInputIncubatorPaymentAccountChange}
                                    onClick={handleCleanValueIncubatorPaymentAccount}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="surname"
                                    label="Фамилия"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueSurname}
                                    onChange={handleInputSurnameChange}
                                    onClick={handleCleanValueSurname}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="tel"
                                    label="Телефон"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueTel}
                                    onChange={handleInputTelChange}
                                    onClick={handleCleanValueTel}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="dateOfBirth"
                                    label="Дата рождения"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueDateOfBirth}
                                    onChange={handleInputDateOfBirthChange}
                                    onClick={handleCleanValueDateOfBirth}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="passport"
                                    label="Паспорт"
                                    variant="outlined"
                                    type="text"
                                    value={inputValuePassport}
                                    onChange={handleInputPassportChange}
                                    onClick={handleCleanValuePassport}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="clientPaymentAccount"
                                    label="Банковский счет клиента"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueClientPaymentAccount}
                                    onChange={handleInputClientPaymentAccountChange}
                                    onClick={handleCleanValueClientPaymentAccount}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Дополнительная информация</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="status"
                                    label="Банковский счет клиента"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueStatus}
                                    onChange={handleInputStatusChange}
                                    onClick={handleCleanValueStatus}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>

                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth sx={inputStyles}>
                                        <InputLabel id="demo-simple-select-label">Ассистент</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentAssistant}
                                            label={"Ассистент"}
                                            onChange={handleChangeAssistant}
                                        >
                                            {assistantItem}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="dateOfCreation"
                                    label="Банковский счет клиента"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueDateOfCreation}
                                    onChange={handleInputDateOfCreationChange}
                                    onClick={handleCleanValueDateOfCreation}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth sx={inputStyles}>
                                        <InputLabel id="legalizationInputLabel">Легализация</InputLabel>
                                        <Select
                                            labelId="legalizationLabelId"
                                            id="legalizationSelectLabel"
                                            value={currentAssistant}
                                            label={"Ассистент"}
                                            onChange={handleChangeAssistant}
                                        >
                                            {assistantItem}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '500', padding: '40px 0px 0px 0px' }}>Действия</Typography>
                    <ActionWithClientInDetails />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Адрес в Польше</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%', padding: '20px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="region"
                                    label="Воеводство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueRegion}
                                    onChange={handleInputRegionChange}
                                    onClick={handleCleanValueRegion}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="mailIndex"
                                    label="Воеводство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueMailIndex}
                                    onChange={handleInputMailIndexChange}
                                    onClick={handleCleanValueMailIndex}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="houseNumber"
                                    label="Номер дома"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueHouseNumber}
                                    onChange={handleInputHouseNumberChange}
                                    onClick={handleCleanValueHouseNumber}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', padding: '20px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="city"
                                    label="Город"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueCity}
                                    onChange={handleInputCityChange}
                                    onClick={handleCleanValueCity}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="street"
                                    label="Улица"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueStreet}
                                    onChange={handleInputStreetChange}
                                    onClick={handleCleanValueStreet}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="appartmentNumber"
                                    label="Номер квартиры"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueAppartmentNumber}
                                    onChange={handleInputAppartmentNumberChange}
                                    onClick={handleCleanValueAppartmentNumber}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}