import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import { Alert } from "@mui/material";
import { useState } from "react";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

const boxMessage = {
    padding: '0px 0px 32px 0px'
};

export const OptionCreateClient = (props) =>{

    const [alertMessage, setAllertMessage] = useState('');
    /* states for inputs */
    const [inputValueName, setInputValueName] = useState('');
    const handleInputNameChange = (event) => {      //name
        const newValue = event.target.value;
        const regex = /^[a-zA-Z]+$/;
        const isValid = regex.test(newValue);
        setInputValueName(newValue)
        if (!isValid && newValue.length !== 0) {
            setAllertMessage(alertErrorIncorrectText)
        }
        else {
            setAllertMessage('')
        }
        console.log(inputValueName)
    };
    const handleCleanValueName = () => {
        setInputValueName('')
    }

    const [mail, setMail] = useState(''); //email
    const setMailHandler = (event) => {
        const newValue = event.target.value;
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const isValid = re.test(newValue);
        setMail(newValue)
        if (!isValid && newValue.length !== 0) {
            setAllertMessage(alertErrorMail)
        } else
            setAllertMessage('')
    };
    const handleCleanValueMail = () => {
        setMail('')
    }

    const [inputValueCitizenship, setInputValueCitizenship] = useState('');
    const handleInputCitizenshipChange = (event) => {      //citizenship
        const newValue = event.target.value;
        setInputValueCitizenship(newValue);
        console.log(newValue);
    };
    const handleCleanValueCitizenship = () => {
        setInputValueCitizenship('')
    }

    const [inputValuePesel, setInputValuePesel] = useState('');
    const handleInputPeselChange = (event) => {      //pesel
        const newValue = event.target.value;
        setInputValuePesel(newValue);
        console.log(newValue);
    };
    const handleCleanValuePesel = () => {
        setInputValuePesel('')
    }

    const [inputValueIncubatorPaymentAccount, setInputValueIncubatorPaymentAccount] = useState('');
    const handleInputIncubatorPaymentAccountChange = (event) => {      //IncubatorPaymentAccount
        const newValue = event.target.value;
        setInputValueIncubatorPaymentAccount(newValue);
        console.log(newValue);
    };
    const handleCleanValueIncubatorPaymentAccount = () => {
        setInputValueIncubatorPaymentAccount('')
    }

    const [inputValueSurname, setInputValueSurname] = useState('');
    const handleInputSurnameChange = (event) => {      //surname
        const newValue = event.target.value;
        setInputValueSurname(newValue);
        console.log(newValue);
    };
    const handleCleanValueSurname = () => {
        setInputValueSurname('')
    }

    const [inputValueTel, setInputValueTel] = useState('');
    const handleInputTelChange = (event) => {      //tel
        const newValue = event.target.value;
        setInputValueTel(newValue);
        console.log(newValue);
    };
    const handleCleanValueTel = () => {
        setInputValueTel('')
    }

    const [inputValueDateOfBirth, setInputValueDateOfBirth] = useState('');
    const handleInputDateOfBirthChange = (event) => {      //dateOfBirth
        const newValue = event.target.value;
        setInputValueDateOfBirth(newValue);
        console.log(newValue);
    };
    const handleCleanValueDateOfBirth = () => {
        setInputValueDateOfBirth('')
    }

    const [inputValuePassport, setInputValuePassport] = useState('');
    const handleInputPassportChange = (event) => {      //passport
        const newValue = event.target.value;
        setInputValuePassport(newValue);
        console.log(newValue);
    };
    const handleCleanValuePassport = () => {
        setInputValuePassport('')
    }

    const [inputValueClientPaymentAccount, setInputValueClientPaymentAccount] = useState('');
    const handleInputClientPaymentAccountChange = (event) => {      //ClientPaymentAccount
        const newValue = event.target.value;
        setInputValueClientPaymentAccount(newValue);
        console.log(newValue);
    };
    const handleCleanValueClientPaymentAccount = () => {
        setInputValueClientPaymentAccount('')
    }

    const [inputValueRegion, setInputValueRegion] = useState('');
    const handleInputRegionChange = (event) => {      //region
        const newValue = event.target.value;
        setInputValueRegion(newValue);
        console.log(newValue);
    };
    const handleCleanValueRegion = () => {
        setInputValueRegion('')
    }

    const [inputValueMailIndex, setInputValueMailIndex] = useState('');
    const handleInputMailIndexChange = (event) => {   //mailIndex 
        const newValue = event.target.value;
        setInputValueMailIndex(newValue);
        console.log(newValue);
    };
    const handleCleanValueMailIndex = () => {
        setInputValueMailIndex('')
    }

    const [inputValueHouseNumber, setInputValueHouseNumber] = useState('');
    const handleInputHouseNumberChange = (event) => {   //houseNumber
        const newValue = event.target.value;
        setInputValueHouseNumber(newValue);
        console.log(newValue);
    };
    const handleCleanValueHouseNumber = () => {
        setInputValueHouseNumber('')
    }

    const [inputValueCity, setInputValueCity] = useState('');
    const handleInputCityChange = (event) => {   //city
        const newValue = event.target.value;
        setInputValueCity(newValue);
        console.log(newValue);
    };
    const handleCleanValueCity = () => {
        setInputValueCity('')
    }

    const [inputValueStreet, setInputValueStreet] = useState('');
    const handleInputStreetChange = (event) => {   //street
        const newValue = event.target.value;
        setInputValueStreet(newValue);
        console.log(newValue);
    };
    const handleCleanValueStreet = () => {
        setInputValueStreet('')
    }

    const [inputValueAppartmentNumber, setInputValueAppartmentNumber] = useState('');
    const handleInputAppartmentNumberChange = (event) => {  //appartmentNumber
        const newValue = event.target.value;
        setInputValueAppartmentNumber(newValue);
        console.log(newValue);
    };
    const handleCleanValueAppartmentNumber = () => {
        setInputValueAppartmentNumber('')
    }
  
    const alertErrorIncorrectText = <Alert severity="error">Только латинские буквы</Alert>;
    const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;
    
    return(
        <Box sx={{
            maxWidth: '1400px', margin: '0 auto'
        }} >
            <Typography sx={{ padding: '30px 0px 20px 0px', maxWidth: '100%' }} variant='h4'>Новый клиент</Typography>
            <Grid container spacing={1} justifyContent={'space-between'} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Основная информация</Typography>
                    <Box sx={{padding:'10px'}}>{alertMessage}</Box>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '20px' }}>

                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="name"
                                    label="Имя"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueName}
                                    onChange={handleInputNameChange}
                                    onClick={handleCleanValueName}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="mail"
                                    label="Email"
                                    variant="outlined"
                                    type="text"
                                    value={mail}
                                    onChange={setMailHandler}
                                    onClick={handleCleanValueMail}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="citizenship"
                                    label="Гражданство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueCitizenship}
                                    onChange={handleInputCitizenshipChange}
                                    onClick={handleCleanValueCitizenship}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="pesel"
                                    label="PESEL"
                                    variant="outlined"
                                    type="text"
                                    value={inputValuePesel}
                                    onChange={handleInputPeselChange}
                                    onClick={handleCleanValuePesel}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="incubatorPaymentAccount"
                                    label="Банковский счёт в инкубаторе"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueIncubatorPaymentAccount}
                                    onChange={handleInputIncubatorPaymentAccountChange}
                                    onClick={handleCleanValueIncubatorPaymentAccount}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="surname"
                                    label="Фамилия"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueSurname}
                                    onChange={handleInputSurnameChange}
                                    onClick={handleCleanValueSurname}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="tel"
                                    label="Телефон"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueTel}
                                    onChange={handleInputTelChange}
                                    onClick={handleCleanValueTel}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="dateOfBirth"
                                    label="Дата рождения"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueDateOfBirth}
                                    onChange={handleInputDateOfBirthChange}
                                    onClick={handleCleanValueDateOfBirth}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="passport"
                                    label="Паспорт"
                                    variant="outlined"
                                    type="text"
                                    value={inputValuePassport}
                                    onChange={handleInputPassportChange}
                                    onClick={handleCleanValuePassport}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="clientPaymentAccount"
                                    label="Банковский счет клиента"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueClientPaymentAccount}
                                    onChange={handleInputClientPaymentAccountChange}
                                    onClick={handleCleanValueClientPaymentAccount}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600',padding:'30px 0px 0px 0px'}}>Адрес в Польше</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="region"
                                    label="Воеводство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueRegion}
                                    onChange={handleInputRegionChange}
                                    onClick={handleCleanValueRegion}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="mailIndex"
                                    label="Воеводство"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueMailIndex}
                                    onChange={handleInputMailIndexChange}
                                    onClick={handleCleanValueMailIndex}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="houseNumber"
                                    label="Номер дома"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueHouseNumber}
                                    onChange={handleInputHouseNumberChange}
                                    onClick={handleCleanValueHouseNumber}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="city"
                                    label="Город"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueCity}
                                    onChange={handleInputCityChange}
                                    onClick={handleCleanValueCity}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="street"
                                    label="Улица"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueStreet}
                                    onChange={handleInputStreetChange}
                                    onClick={handleCleanValueStreet}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '20px' }}>
                                <TextField sx={inputStyles}
                                    fullWidth
                                    id="appartmentNumber"
                                    label="Номер квартиры"
                                    variant="outlined"
                                    type="text"
                                    value={inputValueAppartmentNumber}
                                    onChange={handleInputAppartmentNumberChange}
                                    onClick={handleCleanValueAppartmentNumber}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{padding:'20px 0px 0px 0px'}}>   
                <Button sx={boxSX} variant="contained">добавить</Button>
            </Box>
        </Box>
    )

}