import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Box, Typography, Button } from "@mui/material";
import { blueGrey, green } from "@mui/material/colors";
import ActionWithClients from '../administration-tables-controls-components/ActionWithClients';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


const activeGreen = green[500];
const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayGhost = blueGrey[100];
const grayGhost400 = blueGrey[400];



function createData(id, fullName, email, tel, status, dateOfCreation, assistant, actions) {

  return { id, fullName, email, tel, status, dateOfCreation, assistant, actions };
}

const boxSxActive = {
  width: '110px',
  textAlign: 'center',
  padding: '10px',
  borderRadius: '16px',
  background: activeGreen,
  color: '#ffff'
};
const boxSxInActive = {
  width: '110px',
  textAlign: 'center',
  padding: '10px',
  borderRadius: '16px',
  background: grayGhost,
  color: gray
};
const boxSX = {
  background: gray,
  width: '250px',
  height: '42px',
  "&:hover": {
    border: "1px solid #000",
    color: '#ffff',
    backgroundColor: grayLight
  },
};
const buttonSX = {
  width: '100%',
  fontSize: '11px',
  color: grayGhost400,
  display: 'flex',
  justifyContent: 'flex-start',
  height: '20px',
  "&:hover": {
    backgroundColor: 'inherit',
    color: gray
  },
}

const statusActive = (<Box sx={boxSxActive}>Активный</Box>)
const statusInactive = (<Box sx={boxSxInActive}>Неактивный</Box>)

function OptionClients(props) {

  const navigate = useNavigate();
  const handleClickToclientDetails = (id) => {  //navigate to details
    navigate(`/admin/client/details/${id}`);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      id: 'name',
      label: 'Полное имя',
      minWidth: 100,
      renderCell: (row) =>
        <React.Fragment>
          <Box sx={{ paddingBottom: '10px', }}>{row.fullName}</Box> {/* Добавляем содержимое объекта */}
          <Button sx={buttonSX} onClick={() => {
         
            handleClickToclientDetails(row.id);
          }}>подробнее</Button>
        </React.Fragment>
    },
    {
      id: 'email',
      label: 'Email',
      minWidth: 100
    },
    {
      id: 'tel',
      label: 'Номер телефона',
      minWidth: 140,
      align: 'left',
    },

    {
      id: 'status',
      label: 'Статус',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'dateOfCreation',
      label: 'Добавлен',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'assistant',
      label: 'Ассистент',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Действия',
      minWidth: 220,
      align: 'left',
    },
  ];

  const clientsDataItem = props.clientsData().map((item, index) => (

    createData(
      item.id,
      item.fullName,
      item.email,
      item.tel,
      (item.status === 'active' ? statusActive : statusInactive),
      item.dateOfCreation,
      item.assistant,
      /* action component */
      <ActionWithClients
        name={item.fullName}
        userData={props.userData}
        assistansData={props.assistansData}

      />,
      { key: uuidv4() }
    )
  ));

  return (
    <>
      <Box sx={{
        maxWidth: '1400px', margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '30px 0px 30px 0px'
      }}>
        <Typography variant='h4'>Клиенты</Typography> <Link to='/admin/create/client'><Button sx={boxSX} variant="contained">+ ДОБАВИТЬ КЛИЕНТА</Button></Link>
      </Box>
      <Paper sx={{
        maxWidth: '1400px', margin: '0 auto',
        overflow: 'hidden'
      }}>
        <TableContainer sx={{ maxHeight: 660 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column, index) => (
                  <TableCell
                    key={uuidv4()}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}

                  >
                    {column.label}

                  </TableCell >
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsDataItem
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={uuidv4()}
                      align={'left'}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={uuidv4()} align={column.align}>
                            {column.renderCell ? column.renderCell(row) : (column.format && typeof value === 'number' ? column.format(value) : value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props.clientsData().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
export default OptionClients;
