import * as React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const ActionEdit = (props) => {

    const inputStyles = {
        margin: '10px 0px 10px 0px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayLight,
            },
            '&.Mui-focused fieldset': {
                borderColor: gray,
            },
            underline: {
                '&::placeholder': {
                    color: 'red',
                    opacity: 1,
                }
            },
        },
        '& label.Mui-focused': {
            color: grayLightGhost,
        },
    };

    const boxSX = {
        background: gray,
        width: '40%',
        height: '42px',
        "&:hover": {
            border: "1px solid #000",
            color: '#ffff',
            backgroundColor: grayLight
        },
    };

    return (
        <Grid container spacing={1} direction={'column'}>
            <Typography variant="h4" sx={{ padding: '30px 0px 30px 5px' }}>Пользователь</Typography>
            <Box>
                <Typography sx={{paddingBottom:'10px'}}>Редактирование пользователя</Typography>
                <Typography variant='h5'>{props.name}</Typography>
            </Box>
            <CloseIcon sx={{
                position: 'absolute',
                right: '20px',
                top: '20px',
                cursor:'pointer'
            }}
            onClick={props.handleClose}
             />
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ maxWidth: '552px' }}
            >
                <TextField sx={inputStyles}
                    fullWidth
                    id="name"
                    label="Имя"
                    variant="outlined"
                    type="text"
                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="surname"
                    label="Фамилия"
                    variant="outlined"
                    type="text"
                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="tel"
                    label="Номер телефона"
                    variant="outlined"
                    type="tel"
                />
                <TextField sx={inputStyles}
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                />
                <Box sx={{
                    display: 'flex',
                    padding: '20px 0px 0px 0px'
                }}>
                    <Button sx={boxSX} variant="contained" >Сохранить</Button>
                </Box>
            </Box>
        </Grid>
    )

}
export default ActionEdit