import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import OptionAdministration from '../../components/admin-page-components/admin-page-shells/OptionAdministration';


function Administration(props) {

    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            login={props.login}
            handleLogout={props.handleLogout}
            container={
                <OptionAdministration
                    administrationData={props.administrationData}
                    clientsData={props.clientsData}
                    userData={props.userData}
                />} />
    );

}
export default Administration;
