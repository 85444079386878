import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { blueGrey } from "@mui/material/colors";


const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const calendarStyles = {
  input: {
    borderRadius: '4px',
    
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${grayLightGhost} !important`,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${gray} !important`,
    },
    label: {
      color: gray, 
      '&.Mui-focused': {
        color:grayLightGhost, 
      },
    },
    
  },
};

export default function TextFieldSlotProps(props) {

  const isDateAllowed = (date) => {  // disabled incorrect date
    return date.isBefore(dayjs(), 'day');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer size='small' components={['DatePicker', 'DatePicker', 'DatePicker']}>
        <DatePicker
         sx={{ ...calendarStyles.input }}
          label={props.label}
          slotProps={{ textField: { size: 'small' } }}
          value={props.selectedDate}
          onChange={props.handleDateChange}
          shouldDisableDate={isDateAllowed} // disabled incorrect date
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}