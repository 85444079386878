import * as React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import {Box} from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { blueGrey } from '@mui/material/colors';
import OutsideHook from '../outside-hook/OutsideHook';



const gray = blueGrey[900];
const grayLight = blueGrey[700];

export default function LanguageSelector(props) {

  
  const [ref, isShow,setIsShow] = OutsideHook(false);
  
  const [currentLanguage, setCurrentLanguage] = useState('RU');

  const setLanguage =(language) => {
    setCurrentLanguage(language);
  };

  const langData = ['RU','EN','UA','PL'];
  const langItem = langData.filter((item)=> item !== currentLanguage).map((item)=> (
    <Box key={item} 
        sx={{
            padding:'0px 0px 10px 0px',
            width:'70%',display:'flex',
            opacity:0.6,cursor:"pointer",
            margin:'0 auto',
            "&:hover":{
              opacity:1
            }
            }} 
        onClick={() => {
            setLanguage(item);
            }}>
        <LanguageIcon sx={{marginRight:'10px'}}/><Typography>{item}</Typography>
    </Box>
  ));


  const showDropDown ={
    display:'block',
    padding:'20px 0px 0px 0px',
    width:'120px',
    position:'absolute',
    margin:0,
    borderRadius:'10px',
    background:'rgb(38, 50, 56,0.9)',
    color:'#fff'
  };

  return (
    <Box sx={{
      padding:'0px 40px 0px 0px',
      width:'200px',
      display:'flex',
      justifyContent:'space-between',
      position:'absolute',
      top:'20px',
      right:'20px',
      }}>
      <Box ref={ref} 
        sx={{
            width:'105px',
            
            padding:'0px 20px 0px 0px'
            }} onClick={() => {setIsShow(!isShow)}} 
           >
            <Box 
                sx={{
                    width:'70px',
                    display:'flex',
                    justifyContent:"space-between",
                    margin:'0 auto',
                    cursor:'pointer',
                    color:'#fff',
                    }}>
                <LanguageIcon sx={{marginRight:'10px'}}/><Typography sx={{marginRight:'10px'}}>{currentLanguage}</Typography>
                <Box>{isShow ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }</Box>
            </Box>
            { isShow && (      
              <Box sx={showDropDown} >
                {langItem}
              </Box>
            )
            }
      </Box>
      <Box sx={{padding:0,display:'block',width:'50px'}}>
        <Box sx={{display:'flex',cursor:'pointer'}}  >
            <LogoutIcon sx={{opacity:0.6,"&:hover":{opacity:1}}} onClick={() => props.handleLogout()}/>
        </Box>
      </Box>
    </Box>
  );
}