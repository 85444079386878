import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import { OptionCreateClient } from "../../components/admin-page-components/admin-page-shells/OptionCreateClient";

export const CreateClient = (props) =>{
    
    return(
        <SideNav
        getUserInfo={props.getUserInfo}
        navData={props.navData}
         userData ={props.userData} 
         handleLogout={props.handleLogout}
         container={<OptionCreateClient/>}/>
    )

}