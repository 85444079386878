import * as React from "react";
import { Box, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const gray = blueGrey[900];
const grayLight = blueGrey[400];

const buttonSX = {
    width: '100%',
    fontSize: '12px',
    color: grayLight,
    display: 'flex',
    justifyContent: 'flex-start',
    height: '20px',
    margin:'15px 0px 0px 0px',
    "&:hover": {
        backgroundColor: 'inherit',
        color: gray
    },
}

export const ActionWithClientInDetails = () => {

    return (
        <Box sx={{width:'200px'}}>
            <Button sx={buttonSX}>Заблокировать клиента</Button>
            <Button sx={buttonSX}>Удалить клиента</Button>
            <Button sx={buttonSX}>Сбросить пароль клиента</Button>
        </Box>
    )

}