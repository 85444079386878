import React from "react";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import loginLogo from '../../assets/img/loginLogo.svg';
import { Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { blueGrey } from "@mui/material/colors";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const gridStyles = {
    width: '100%',
    height: '100vh',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
};

const boxStyles = {
    width: '320px',
};

const boxImg = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 0px 32px 0px'
};

const boxMessage = {
    padding: '16px 0px 16px 0px'
};

const boxSX = {
    background: gray,
    width: '100%',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const inputStyles = {
    margin: '10px 0px 10px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const RestorePass = (props) => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    const [alertMessage, setAllertMessage] = useState('');
    const alertMessagehandler = (value) => {
        setAllertMessage(value)
    }

    const [consent, setConsent] = useState(false);

    const [confirmCode, setConfirmCode] = useState('');
    const handleInputConfirmCode = (event) => {
        const value = event.target.value;
        setConfirmCode(value);
        console.log(value)
    };

    const [newPass, setNewPass] = useState('');
    const handleInputNewPass = (event) => {
        const value = event.target.value;
        setNewPass(value);
        console.log(value)
    };

    const [confirmPass, setConfirmPass] = useState('');
    const handleInputConfirmPass = (event) => {
        const value = event.target.value;
        setConfirmPass(value);
        console.log(value)
    };


    const alertErrorMail = <Alert severity="error">Неверный адрес email </Alert>;
    const sendMailAlertSuccess = <Alert severity="success">Письмо отправлено на почту</Alert>;
    const alertErrorPass = <Alert severity="error">Неверный пароль </Alert>;

    const cleanValues = (code,newPass,confirmPass) => {
        code('');
        newPass('');
        confirmPass('');
    }
    let outCode = (code !== null) ? code : confirmCode;
    console.log('outCode -' + ' ' + outCode)

    useEffect(() => {
        if(newPass === confirmPass){
            setConsent(true)
        }
        else{
            setConsent(false)
        }
        
    }, [newPass,confirmPass]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAllertMessage('');
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [alertMessage]);

    const onClickSendButtonHandler = () => {    // push pass from back
        if(!consent) {
            console.log('not valid');
        }
        if(consent) {
            try {
                axios.post(`https://crm.weexpert.io/api/user/000.php`, {
                    code:outCode,
                    newPassword: newPass,
                },).then((response) => {
                    console.log(response)
                    cleanValues()
    
                });
            } catch (e) {
                console.log(e);
    
            } 
        }  else alertMessagehandler(alertErrorPass)
        
    };

    return (
        <>
            <AppBarForLogin />
            <Grid sx={gridStyles}>
                <Box
                    component="form"
                    sx={boxStyles}
                    noValidate
                    autoComplete="off"
                    method="post"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <Box sx={boxImg}><img sx={{ width: '100%', display: 'block', }} src={loginLogo} alt="logo"></img></Box>
                    <Box sx={{ fontSize: 20, textAlign: 'center' }}>Восстановление пароля</Box>
                    <Box sx={{ fontSize: 14, textAlign: 'center',padding:'10px 0px 0px 0px'}}>Пожалуйста проверьте почту</Box>
                    <Box sx={boxMessage}>{alertMessage}</Box>
                    { code === null && (
                        <TextField sx={inputStyles}
                        fullWidth
                        label="Code"
                        variant="outlined"
                        type="text"
                        value={confirmCode}
                        onChange={handleInputConfirmCode}
                    />
                    )}
                    <TextField
                        sx={inputStyles}
                        fullWidth
                        id="newPass"
                        label="Новый пароль"
                        variant="outlined"
                        type="text"
                        value={newPass}
                        onChange={handleInputNewPass}
                    />
                    <TextField
                        sx={inputStyles}
                        fullWidth
                        id="confirmNewPass"
                        label="Подтвердите новый пароль"
                        variant="outlined"
                        type="text"
                        value={confirmPass}
                        onChange={handleInputConfirmPass}
                    />
                    <Button sx={boxSX} variant="contained" onClick={() => {
                        onClickSendButtonHandler();
                    }}>Всстановить</Button>
                </Box>
            </Grid>
        </>
    );
}
