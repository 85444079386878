import * as React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import PopUp from "../../modal/PopUp";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from "@mui/material";
import ActionAssignClient from '../admin-modal-components/modal-pages-for-option-administration/ActionAssign';
import axios from "axios";
import { useState } from 'react';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const inputStyles = {
    margin: '10px 0px 10px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

const boxSX = {
    background: gray,
    width: '40%',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const accodrdeonDetailsRights = {
    paddingBottom: '20px',
    cursor: 'pointer',
    opacity: '0.5',
    "&:hover": {
        opacity: '1'
    },
}

const buttonSX = {
    width: '170px',
    fontSize: '14px',
    color: grayLight,
    display: 'flex',
    justifyContent: 'flex-start',
    height: '20px',
    padding: 0,
    mt: 2,
    opacity: '0.6',
    "&:hover": {
        backgroundColor: 'inherit',
        opacity: '1'
    },
};

const boxMessage = {
    width: '280px',
    padding: '0px 0px 32px 0px'
};

const OptionCreateNewUser = (props) => {
    const [alertMessage, setAllertMessage] = useState();
    const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);


    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [values, setValues] = useState(['Администратор', 'Ассистент', 'Другое']);

    const handleAccordionSummaryClick = () => {
        setIsAccordionOpen(!isAccordionOpen);
      };

    const handleTypographyClick = (value) => {
        setSelectedValue(value);
        setIsAccordionOpen(false);
    };


    const [inputValueName, setInputValueName] = useState('');
    const [inputValueSurname, setInputValueSurname] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');

    const handleCleanValueName = () => {
        setInputValueName('')
    };
    const handleCleanValueSurname = () => {
        setInputValueSurname('')
    };
    const handleCleanValueTel = () => {
        setTel('')
    }
    const handleCleanValueMail = () => {
        setMail('')
    }
    const cleanValues = (name, lastname, email, tel) => {

        name('');
        lastname('');
        email('');
        tel("");
    };
    const handleInputNameChange = (event) => {
        const value = event.target.value;
        console.log(event.target.value)
        const regex = /^[a-zA-Z]+$/;
        const isValid = regex.test(value);

        setInputValueName(value);
        if (!isValid && value.length !== 0) {
            setAllertMessage(alertErrorIncorrectText)
        }
        else {
            setAllertMessage('')
        }

    };

    const handleInputSurnameChange = (event) => {
        const value = event.target.value;
        console.log(value)
        const regex = /^[a-zA-Z]+$/;
        const isValid = regex.test(value);

        setInputValueSurname(value);
        if (!isValid && value.length !== 0) {
            setAllertMessage(alertErrorIncorrectText)
        }
        else {
            setAllertMessage('')
        }
    };

    const handleInputTelChange = (event) => {
        const value = event.target.value;
        setTel(value);
        console.log(value)
    }



    const setMailHandler = (event) => {
        setMail((prev) => {
            prev = event.target.value;
            console.log(prev)
            const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
                setAllertMessage(alertErrorMail)
            } else
                setAllertMessage('')
            return prev;
        });
    };

    const alertErrorIncorrectText = <Alert severity="error">Только латинские буквы</Alert>;
    const alertErrorMail = <Alert severity="error">Некорректный адрес Email</Alert>;
    const alertSuccess = <Alert severity="success">Данные успешно изменены</Alert>;

    const onClickSendButtonHandler = () => {    // push userData from back
        try {
            axios.post(`https://crm.weexpert.io/api/user/000.php`, {
                id: props.rights,
                firstname: inputValueName,
                lastname: inputValueSurname,
                tel: tel,
                email: mail
            },).then((response) => {
                const responseData = response.data;
                props.userDataHandler(responseData)

                cleanValues(
                    setInputValueName,
                    setInputValueSurname,
                    setMail, 
                    setTel
                );

            });
        } catch (e) {
            console.log(e);

        }
    };

    return (
        <Grid container spacing={1} direction={'column'}>
            <Typography variant="h4" sx={{ padding: '30px 0px 30px 5px' }}>Новый пользователь</Typography>
            <Box sx={boxMessage}>{alertMessage}</Box>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                method="post"
                onSubmit={(e) => e.preventDefault()}
                sx={{ maxWidth: '552px' }}
            >
                <TextField sx={inputStyles}
                    fullWidth
                    id="name"
                    label="Имя"
                    variant="outlined"
                    type="text"
                    value={inputValueName}
                    onClick={handleCleanValueName}
                    onChange={handleInputNameChange}

                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="surname"
                    label="Фамилия"
                    variant="outlined"
                    type="text"
                    value={inputValueSurname}
                    onClick={handleCleanValueSurname}
                    onChange={handleInputSurnameChange}
                />
                <TextField
                    sx={inputStyles}
                    fullWidth
                    id="tel"
                    label="Номер телефона"
                    variant="outlined"
                    type="tel"
                    value={tel}
                    onClick={handleCleanValueTel}
                    onChange={handleInputTelChange}
                />
                <TextField sx={inputStyles}
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={mail}
                    onClick={handleCleanValueMail}
                    onChange={setMailHandler}
                />
                 <Accordion expanded={isAccordionOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleAccordionSummaryClick}
      >
        <Typography>Роль: {selectedValue || 'Выберите роль'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {values.map((value) => (
          <Typography
            key={value}
            sx={accodrdeonDetailsRights}
            onClick={() => handleTypographyClick(value)}
          >
            {value}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
                
                <Typography sx={{ mt: 3, mb: 3 }}>Клиенты</Typography>
                <Typography>{'{`сюда летят клиенты`}'}</Typography>
                <PopUp sx={buttonSX}
                    name={'Присвоить клиента'}
                    container={
                        <ActionAssignClient
                            handleClose={handleCloseAddClient}
                            data={props.clientsData}
                            userData={props.userData}
                        />}
                    open={openAddClient}
                    handleOpen={handleOpenAddClient}
                    handleClose={handleCloseAddClient}
                    width={1200}
                />
                <Box sx={{
                    display: 'flex',
                    padding: '20px 0px 0px 0px'
                }}>
                    <Button sx={boxSX} variant="contained" onClick={() => {
                        onClickSendButtonHandler();
                    }} >Добавить</Button>
                </Box>
            </Box>
        </Grid>
    )

}
export default OptionCreateNewUser