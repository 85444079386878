import React from "react";
import SideNav from '../../components/SideNav/Sidenav';
import OptionInvoices from "../../components/admin-page-components/admin-page-shells/OptionInvoices";

const Invoices = (props) => {
    return (
        <SideNav
        getUserInfo={props.getUserInfo}
            navData={props.navData}
            userData={props.userData}
            login={props.login}
            handleLogout={props.handleLogout}
            container={
                <OptionInvoices
                    invoicesData={props.invoicesData}
                />} />
    )

}
export default Invoices