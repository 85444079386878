import * as React from "react";
import { Box, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useState } from "react";
import PopUp from "../../modal/PopUp";
import ActionAssign from "../admin-modal-components/modal-pages-for-option-administration/ActionAssign";
import ActionEdit from "../admin-modal-components/modal-pages-for-option-administration/ActionEdit";
const gray = blueGrey[900];
const grayLight = blueGrey[400];


const ActionWithClients = (props) => {

    const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);

    const [openEditUser, setOpenEditUser] = useState(false);
    const handleOpenEditUser = () => setOpenEditUser(true);
    const handleCloseEditUser = () => setOpenEditUser(false);
    const buttonSX = {
        width: '100%',
        fontSize: '11px',
        color: grayLight,
        display: 'flex',
        justifyContent: 'flex-start',
        height: '20px',
        "&:hover": {
            backgroundColor: 'inherit',
            color: gray
        },
    }
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', }}>
                <PopUp sx={buttonSX}
                    name={"Присвоить ассистента"}
                    container={
                        <ActionAssign
                            header={"Присвоить ассистента"}
                            handleClose={handleCloseAddClient}
                            data={props.assistansData}
                            userData={props.userData}
                        />}
                    open={openAddClient}
                    handleOpen={handleOpenAddClient}
                    handleClose={handleCloseAddClient}
                    width={1200}
                />
                <PopUp sx={buttonSX}
                    name={'Редактировать'}
                    container={<ActionEdit
                        name={props.name}
                        handleClose={handleCloseEditUser}
                        userData={props.userData}
                    />}
                    open={openEditUser}
                    handleOpen={handleOpenEditUser}
                    handleClose={handleCloseEditUser}
                    width={800}
                    
                />
                <Button sx={buttonSX}>Заблокировать</Button>
                <Button sx={buttonSX}>Удалить</Button>
            </Box>
        </>
    )

}
export default ActionWithClients;