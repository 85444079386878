import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { grey } from '@mui/material/colors';


const textColor = grey[900];



 function OptionMain(props) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    const itemStyle = {
        width:'368px',
        height:'220px',
        margin:'20px auto',
        padding:'20px',
        color:textColor
    };
    const mainBoxStyle = {
        width:'100%',
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-between'
    }
    const cardBoxStyle ={
        width:'100%',
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-between',
        padding:'20px 0px 0px 0px'
    };
    const iconStyle ={
        opacity:0.3
    };
    const personValuesStyles = {
        textAlign:'start',
        padding:'0px 0px 0px 0px',
        fontWeight:'semi-bold'
    };

    return(
        <Grid container spacing={1} sx={{padding:0}} >
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" sx={{padding:'30px 0px 30px 5px'}}>Главные показатели</Typography>
            </Box>
            {props.indicatorsKPI().map((item, index) => (
            <Box key={{index}} sx={mainBoxStyle}>
            
                <Item sx={itemStyle}>
                    <Box sx={{display:'flex',padding:'0px 0px 20px 0px'}} >
                        <PeopleAltIcon sx={iconStyle}/><Typography sx={{padding:'0px 0px 0px 10px'}}>Общее колличество клиентов</Typography>
                    </Box>
                    <Typography variant='h4' sx={personValuesStyles}>{item.totalClients}</Typography>
                    <Box sx={cardBoxStyle}>
                        <Box sx={{display:'flex'}}>
                            <PersonIcon sx={iconStyle}/>
                            <Typography sx={{padding:"0px 0px 0px 10px"}}>Активные </Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <PersonOffIcon sx={iconStyle}/>
                            <Typography sx={{padding:"0px 0px 0px 10px"}}>Неактивные</Typography>
                        </Box>
                    </Box>
                    <Box sx={cardBoxStyle}>
                        <Box sx={{width:'60%'}}>
                            <Typography variant='h4' sx={personValuesStyles}>{item.activeClients}</Typography>
                        </Box>
                        <Box sx={{width:'40%'}}>
                            <Typography variant='h4' sx={personValuesStyles}>{item.nonActiveCluents}</Typography>
                        </Box>
                    </Box>    
                </Item>
                <Item sx={itemStyle}>
                    <Box sx={{display:'flex'}}>
                        <ReceiptLongIcon sx={iconStyle}/><Typography sx={{padding:"0px 0px 20px 10px"}}>Фактуры</Typography>
                    </Box>
                    <Typography variant='h4' sx={personValuesStyles}>{item.invoices}</Typography>
                    <Typography sx={{padding:'20px 0px 0px 0px',textAlign:'start'}}>В ожидании одобрения</Typography>
                </Item>
                <Item sx={itemStyle}>
                    <Box sx={{display:'flex'}}>
                        <ReceiptLongIcon sx={iconStyle}/><Typography sx={{padding:"0px 0px 20px 10px"}}>Контракты</Typography>
                    </Box>
                    <Typography variant='h4' sx={personValuesStyles}>{item.contracts}</Typography>
                    <Typography sx={{padding:'20px 0px 0px 0px',textAlign:'start'}}>В ожидании одобрения</Typography>
                </Item>
            </Box>
            ))}
        </Grid>
    );

}
export default OptionMain;
