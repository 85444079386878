import React from "react";
import LanguageSelector from "../language-selector/LanguageSelector";
import { blueGrey } from "@mui/material/colors";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

const gray = blueGrey[900];
const grayLight = blueGrey[700];

const AppBarForLogin = (props) => {
    return (
        <AppBar position="fixed" >
            <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', color:"#fff",background:gray }}>
                <Typography variant="h6" style={{ position: 'absolute', left: '80px', top: '15px',color:'#fff' }}>
                    weexpert CRM
                </Typography>
                <LanguageSelector handleLogout={props.handleLogout} />
            </Toolbar>
        </AppBar>
    )

}
export default AppBarForLogin