import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextFieldCalendar from "../invoice-create-components/TextFieldCalendar";

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];
const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    width: '100%',
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const PaymentBlock = (props) => {

    return (

        <Grid container spacing={1} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
            <Box sx={boxData}>
                <Box sx={{ width: '48%' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Оплата</Typography>
                    <Box sx={{ paddingTop: '20px' }}>
                        <FormControl size="small" fullWidth sx={inputStyles}>
                            <InputLabel id="demo-simple-select-label">Транкзакция</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={"Транкзакция"}
                                value={props.currentTransactionType}
                                onChange={props.handleChangeTransactionType}
                            >
                                {props.transactionData.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <FormControl size="small" fullWidth sx={inputStyles}>
                            <InputLabel id="demo-simple-select-label">Способ оплаты</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={"Способ оплаты"}
                                value={props.currentPaymentType}
                                onChange={props.handleChangePaymentType}
                            >
                                {props.paymentMethod.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <FormControl size="small" fullWidth sx={inputStyles}>
                            <InputLabel id="demo-simple-select-label">Статус оплаты</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={"Статус оплаты"}
                                value={props.currentPaymentStatus}
                                onChange={props.handleChangePaymentStatus}
                            >
                                {props.paymentStatus.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextFieldCalendar label='Оплата до' selectedDate={props.selectedPaymentTerm} handleDateChange={props.handleDateChangePaymentTerm} />
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <FormControl size="small" fullWidth sx={inputStyles}>
                            <InputLabel id="demo-simple-select-label">Валюта</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={"Валюта"}
                                value={props.currentCurrency}
                                onChange={props.handleChangeCurrency}
                            >
                                {props.currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField size="small" sx={inputStyles}
                            fullWidth
                            id="adress"
                            label="Курс обмена на PLN"
                            variant="outlined"
                            type="text"
                        />
                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField size="small" sx={inputStyles}
                            fullWidth
                            id="bankAccount"
                            label="Банковский счет"
                            variant="outlined"
                            type="text"
                            value={props.currentBankAccount}
                        />
                    </Box>
                </Box>
                <Box sx={{ width: '48%' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Комментарий на фактуре</Typography>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField size="small"
                            sx={inputStyles}
                            id="outlined-multiline-static"
                            label="Комментарий на фактуре"
                            multiline
                            minRows={2}
                            maxRows={5}
                            variant="outlined"
                            inputProps={{ maxLength: 100 }}  //max text length
                            value={props.currentInvoiceComment}
                            onChange={props.handleChangeInvoiceComment}
                        />
                    </Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 0px 0px' }}>Комментарий внутренний</Typography>
                    <Box sx={{ paddingTop: '20px' }}>
                        <TextField size="small"
                            sx={inputStyles}
                            id="outlined-multiline-static"
                            label="Комментарий внутренний"
                            multiline
                            minRows={2}
                            maxRows={5}
                            variant="outlined"
                            inputProps={{ maxLength: 100 }} // max text length
                            value={props.currentInternalComment}
                            onChange={props.handleChangeInternalComment}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    )

}