import * as React from 'react';
import { green, blueGrey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { Alert } from "@mui/material";


const activeGreen = green[500];
const gray = blueGrey[900];
const grayGhost = blueGrey[100];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const inputStyles = {
    width: '100%',
    paddingBottom: '24px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};
const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
};

const boxSX = {
    background:gray,
    width:'50%',
    height:'42px',
    "&:hover": {
      border: "1px solid #000",
      color: '#ffff',
      backgroundColor: grayLight
    },
  };

export default function ActionAssignContractorsFormForInvoice(props) {

    const [alertMessage, setAllertMessage] = useState();

    const [currentCountryOfCompany, setCurrentCountryOfCompany] = useState("");
    const handleChangeCountryOfCompany = (event) => {   //get company registration country
        setCurrentCountryOfCompany(event.target.value);
        console.log(event.target.value)
    };

    const [currentNewContractor, setCurrentNewContractor] = useState("");
    const handleChangeNewContractor = (event) => {   //get company registration country
        setCurrentNewContractor(event.target.value);
        console.log(event.target.value)
    };

    const [currentCompanyType, setCurrentCompanyType] = useState('');
    const handleChangeCompanyType = (event) => {   //get type company or individual
        setCurrentCompanyType(event.target.value);
        console.log(event.target.value)
    };

    const [mail, setMail] = useState('');

    const setMailHandler = (event) => {
        setMail((prev) => {
            prev = event.target.value;
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(String(prev).toLocaleLowerCase()) && prev.length !== 0) {
                setAllertMessage(alertErrorMail)
            } else
                setAllertMessage('')
            return prev;
        });
    };

    const [currentAdress, setCurrentAdress] = useState('');
    const handleChangeAdress = (event) => {   //get adress
        setCurrentAdress(event.target.value);
        console.log(event.target.value)
    };

    const [currentMailIndex, setCurrentMailIndex] = useState('');
    const handleChangeMailIndex = (event) => {   //get mail index
        setCurrentMailIndex(event.target.value);
        console.log(event.target.value)
    };

    const [currentCity, setCurrentCity] = useState("");
    const handleChangeCity = (event) => {   //get valid city
        setCurrentCity(event.target.value);
        console.log(event.target.value)
    };

    const [currentCountry, setCurrentCountry] = useState("");
    const handleChangeCountry = (event) => {   //get company registration country
        setCurrentCountry(event.target.value);
        console.log(event.target.value)
    };

    const alertErrorMail = <Alert sx={{ width: '100%' }} severity="error">Некорректный адрес Email</Alert>;

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h5" sx={{ padding: '0px 0px 10px 0px' }}>{props.header}</Typography>
            <CloseIcon sx={{
                position: 'absolute',
                right: '25px',
                top: '25px',
                cursor: 'pointer'
            }}
                onClick={props.handleClose}
            />
            <Grid container spacing={1}>
                {alertMessage}
                <Box sx={boxData}>
                    <Box sx={{ width: '400px',margin:'0px 10px 0px 0px' }}>
                        <Box sx={{ paddingTop: '20px'}} >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="client"
                                label="Клиент"
                                variant="outlined"
                                type="text"
                                value={props.currentClient}   // invoiceCreate - client
                            />
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="contractor"
                                label="Контрагент"
                                variant="outlined"
                                type="text"
                                value={currentNewContractor}
                                onChange={handleChangeNewContractor}
                            />
                        </Box>
                        <Box >
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Происхождение</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"    //countryData
                                    label={"Происхождение"}
                                    value={currentCountryOfCompany}
                                    onChange={handleChangeCountryOfCompany}
                                >
                                    {props.countryData.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="numberOfDocument"
                                label="Название"
                                variant="outlined"
                                type="text"
                            />
                        </Box>
                        { currentCompanyType === 'Компания' && (
                        < Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="nip"
                                label="NIP"
                                variant="outlined"
                                type="text"
                            />
                        </Box>
                       )}
                        <TextField size="small" sx={inputStyles}
                            fullWidth
                            id="email"
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={mail}
                            onChange={setMailHandler}
                        />
                        <Button sx={boxSX} variant="contained" >Сохранить</Button>
                    </Box>
                    <Box sx={{ width: '400px',margin:'0px 0px 0px 10px' }}>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={"Тип"}
                                    value={currentCompanyType}
                                    onChange={handleChangeCompanyType}
                                >
                                    {props.companyData.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="adress"
                                label="Адрес"
                                variant="outlined"
                                type="text"
                                value={currentAdress}
                                onChange={handleChangeAdress}
                            />
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="mailIndex"
                                label="Почтовый индекс"
                                variant="outlined"
                                type="text"
                                value={currentMailIndex}
                                onChange={handleChangeMailIndex}
                            />
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="city"
                                label="Город"
                                variant="outlined"
                                type="text"
                                value={currentCity}
                                onChange={handleChangeCity}

                            />
                        </Box>
                        <Box >
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="country"
                                label="Страна"
                                variant="outlined"
                                type="text"
                                value={currentCountry}
                                onChange={handleChangeCountry}
                            />
                        </Box>

                    </Box>
                </Box>
            </Grid>
            
        </Box>
    );
}

