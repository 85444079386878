import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";

import { v4 as uuidv4 } from 'uuid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};
const inputStyles = {
    minWidth: '80px',
    
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};
const activeInputStyles = {
    minWidth:'80px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: grayLight, 
      },
      '&.Mui-focused fieldset': {
        borderColor: gray, 
      },
      underline: {
        '&::placeholder': {
          color: 'purple', 
          opacity: 1,
        },
      },
    },
    '& label.Mui-focused': {
      color: gray, 
    },
  };

const vatData = ['23%', '8%', '5%', '0%', 'zw.', 'np.', 'np.EU', '0% WDT', '0%exp.']

export const PositionBlock = (props) => {

    return (
        <Grid container spacing={1} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 40px 0px' }}>Позиции</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow key={uuidv4()}>
                            <TableCell>Название</TableCell>
                            <TableCell align="right">PKWIU</TableCell>
                            <TableCell align="right">Кол-во</TableCell>
                            <TableCell align="right">Единица</TableCell>
                            <TableCell align="right">Цена за единицу нетто</TableCell>
                            <TableCell align="right">Цена нетто</TableCell>
                            <TableCell align="right">Ставка VAT</TableCell>
                            <TableCell align="right">VAT</TableCell>
                            <TableCell align="right">Цена брутто</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow key={row.id}
                            >
                                <TableCell >
                                    <TextField sx={{...inputStyles, margin: '12px 0px 12px 0px' }}
                                        size='small'
                                        align="right"
                                        label={'Название'}
                                        value={row.name}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'name', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        variant="outlined"
                                        type="text"
                                        size='small'
                                        align="right"
                                        label={'PKWIU'}
                                        value={row.pkwiu}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'pkwiu', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'Количество'}
                                        value={row.quantity}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'quantity', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'Единица'}
                                        value={row.unit}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'unit', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'Цена за ед.'}
                                        value={row.unitPriceNetto}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'unitPriceNetto', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'Цена нетто'}
                                        value={row.priceNetto}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'priceNetto', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <FormControl size="small" fullWidth sx={activeInputStyles}>
                                        <InputLabel id="demo-simple-select-label">VAT</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={"Ставка VAT"}
                                            value={row.vatRate}
                                            onChange={(e) => props.handleRowFieldChange(row.id, 'vatRate', e.target.value)}
                                        >
                                            {vatData.map((option,index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'VAT'}
                                        value={row.vat}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'vat', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField sx={inputStyles}
                                        size='small'
                                        align="right"
                                        label={'Цена брутто'}
                                        value={isNaN(row.priceBrutto) ? '' : parseFloat(row.priceBrutto)}
                                        onChange={(e) => props.handleRowFieldChange(row.id, 'priceBrutto', e.target.value)}
                                    ></TextField>
                                </TableCell>
                                <TableCell> <IconButton onClick={() => props.handleDeleteRow(row.id)} aria-label="delete row">
                                    <DeleteIcon />
                                </IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ padding: '40px 0px 0px 0px ' }}>
                <Button sx={boxSX} variant="contained" onClick={props.addNewRow}>+ Добавить позицию</Button>
            </Box>
        </Grid>
    )
}