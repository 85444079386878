import * as React from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { blueGrey } from "@mui/material/colors";
import { Alert } from "@mui/material";
import { useState, useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextFieldCalendar from "./TextFieldCalendar";
import dayjs from 'dayjs';

const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];
const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const inputStyles = {
    width: '100%',
    margin: '0px 0px 0px 0px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};

export const MainCreateInvoicesInputs = (props) =>{

    const invoicesType = [
        {
            id: 'proformaInvoice',
            value: 'Фактура проформа'
        },
        {
            id: 'saleInvoice',
            value: 'Фактура продажи'
        },
        {
            id: 'prepaidInvoice',
            value: 'Фактура предоплаты'
        }];
    return(
        <Grid container spacing={1} justifyContent={'space-between'} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Основные данные</Typography>
                <Box sx={boxData}>
                    <Box sx={{ width: '48%' }}>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Клиент</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={"Клиент"}
                                    value={props.currentClient}
                                    onChange={props.handleChangeClient}
                                >
                                    {props.clientsData().map((option) => (
                                        <MenuItem key={option.id} value={option.fullName}>
                                            {option.fullName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Тип фактуры</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={"Тип фактуры"}
                                    value={props.currentInvoice}
                                    onChange={props.handleChangeInvoice}
                                >
                                    {invoicesType.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="numberOfDocument"
                                label="Номер документа"     //autogenerate - no access to edit
                                variant="outlined"
                                type="text"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '48%' }}>
                        <Box sx={{ paddingTop: '20px' }}>
                            <TextField size="small" sx={inputStyles}
                                fullWidth
                                id="dateOfIssue"                  //autogenerate - no access to edit
                                variant="outlined"
                                type="text"
                                value={props.dateTime}
                                InputProps={{
                                    readOnly: true, // 
                                }}
                                placeholder={props.formatDateTime()} // current date
                            />
                        </Box>
                        <Box sx={{ paddingTop: '12px' }}>
                            <TextFieldCalendar  label='Дата продажи' selectedDate={props.selectedDateOfSale} handleDateChange={props.handleDateChangeDateOfSale} />
                        </Box>
                        <Box sx={{ paddingTop: '20px' }}>
                            <FormControl size="small" fullWidth sx={inputStyles}>
                                <InputLabel id="demo-simple-select-label">Cтатус</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={"Cтатус"}
                                    value={props.currentStatus}
                                    onChange={props.handleChangeStatus}
                                >
                                    {props.statusData.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Grid>
    )

}