import * as React from "react";
import { Box, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import PopUp from "../../modal/PopUp";
import ActionEdit from "../admin-modal-components/modal-pages-for-option-administration/ActionEdit";
import ActionAssign from "../admin-modal-components/modal-pages-for-option-administration/ActionAssign";
import { useState } from "react";

const gray = blueGrey[900];
const grayLight = blueGrey[400];

const ActionWithUsers = (props) => {

    const [openAddClient, setOpenAddClient] = useState(false);
    const handleOpenAddClient = () => setOpenAddClient(true);
    const handleCloseAddClient = () => setOpenAddClient(false);

    const [openEditUser, setOpenEditUser] = useState(false);
    const handleOpenEditUser = () => setOpenEditUser(true);
    const handleCloseEditUser = () => setOpenEditUser(false);

    const buttonSX = {
        width: '90%',
        fontSize: '11px',
        color: grayLight,
        display: 'flex',
        justifyContent: 'flex-start',
        height: '20px',
        "&:hover": {
            backgroundColor: 'inherit',
            color: gray
        },
    }
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', }}>
                <PopUp sx={buttonSX}
                    name={'Присвоить клиента'}
                    container={

                        <ActionAssign
                            header={"Присвоить клиента"}
                            rights={props.rights}
                            name={props.name}
                            handleClose={handleCloseAddClient}
                            data={props.clientsData}
                            userData={props.userData}
                        />}
                    open={openAddClient}
                    handleOpen={handleOpenAddClient}
                    handleClose={handleCloseAddClient}
                    width={1200}
                />
                <PopUp sx={buttonSX}
                    name={'Редактировать'}
                    container={<ActionEdit
                        name={props.name}
                        selectedRow={props.selectedRow}
                        handleClose={handleCloseEditUser}
                        userData={props.userData}
                    />}
                    open={openEditUser}
                    handleOpen={handleOpenEditUser}
                    handleClose={handleCloseEditUser}
                    width={800}
                />
                <Button sx={buttonSX}>Заблокировать</Button>
                <Button sx={buttonSX}>Удалить</Button>
            </Box>
        </>
    )

}
export default ActionWithUsers;