import * as React from 'react';
import { green, blueGrey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { Alert } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';


const activeGreen = green[500];
const gray = blueGrey[900];
const grayGhost = blueGrey[100];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const inputStyles = {
    width: '550px',
    paddingBottom: '24px',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: grayLight,
        },
        '&.Mui-focused fieldset': {
            borderColor: gray,
        },
        underline: {
            '&::placeholder': {
                color: 'red',
                opacity: 1,
            }
        },
    },
    '& label.Mui-focused': {
        color: grayLightGhost,
    },
};
const boxData = {
    width: '100%',
    padding: '50px 30px 30px 30px'
};

const boxSX = {
    background: gray,
    width: '50%',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

export default function ActionReportVat(props) {

    return (
        <Box sx={{ width: '100%' }}>
            
            <CloseIcon sx={{
                position: 'absolute',
                right: '25px',
                top: '25px',
                cursor: 'pointer'
            }}
                onClick={props.handleClose}
            />

            <Box sx={boxData}>
            <Typography variant="h5" sx={{ padding: '20px 0px 30px 0px' }}>{props.header}</Typography>
                <Box >
                    <FormControl size="small" fullWidth sx={inputStyles}>
                        <InputLabel id="demo-simple-select-label">Месяц</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"    //countryData
                            label={"Месяц"}

                        >
                            {/* {props.countryData.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>    set month
                                        {option.value}
                                    </MenuItem>
                               ))}*/}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl size="small" fullWidth sx={inputStyles}>
                        <InputLabel id="demo-simple-select-label">Год</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"    //countryData
                            label={"Год"}

                        >
                            {/*} {props.countryData.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>   set year
                                        {option.value}
                                    </MenuItem>
                               ))}*/}
                        </Select>
                    </FormControl>
                </Box>
                <Button sx={boxSX} variant="contained" ><DownloadIcon sx={{margin:'0px 10px 0px 0px '}}/> Скачать</Button>
            </Box>

        </Box>
    );
}

