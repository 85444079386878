import * as React from 'react';
import { blueGrey } from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const gray = blueGrey[900];
const grayLight = blueGrey[700];
const grayLightGhost = blueGrey[300];

const boxSX = {
    background: gray,
    width: '250px',
    height: '42px',
    "&:hover": {
        border: "1px solid #000",
        color: '#ffff',
        backgroundColor: grayLight
    },
};

const boxData = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}

const companyData = [
    {
        id: 'company',
        value: 'Компания'
    },
    {
        id: 'individual',
        value: 'Физическое лицо'
    },
];

const countryData = [
    {
        id: 'poland',
        value: 'Польша'
    },
    {
        id: 'eu',
        value: 'ЕС'
    },
    {
        id: 'otherWorld',
        value: 'Остальной мир'
    },
];

const EditContractor = (props) => {
    const [currentCompanyType, setCurrentCompanyType] = useState('');
    const handleChangeCompanyType = (event) => {   //get type company or individual
        setCurrentCompanyType(event.target.value);
        console.log(event.target.value)
    };

    const [currentNip, setCurrentNip] = useState('');
    const handleChangeNip = (event) => {   //get NIP
        setCurrentNip(event.target.value);
        console.log(event.target.value)
    };

    const [currentCountryOfCompany, setCurrentCountryOfCompany] = useState("");
    const handleChangeCountryOfCompany = (event) => {   //get company registration country
        setCurrentCountryOfCompany(event.target.value);
        console.log(event.target.value)
    };

    const [currentAdress, setCurrentAdress] = useState('');
    const handleChangeAdress = (event) => {   //get adress
        setCurrentAdress(event.target.value);
        console.log(event.target.value)
    };

    const [currentCity, setCurrentCity] = useState("");
    const handleChangeCity = (event) => {   //get valid city
        setCurrentCity(event.target.value);
        console.log(event.target.value)
    };

    const [mail, setMail] = useState('');
    const setMailHandler = (event) => {
        setMail(event.target.value);
        console.log(event.target.value)
    };

    const [currentMailIndex, setCurrentMailIndex] = useState('');
    const handleChangeMailIndex = (event) => {   //get mail index
        setCurrentMailIndex(event.target.value);
        console.log(event.target.value)
    };

    const [currentCountry, setCurrentCountry] = useState('');
    const handleChangeCountry = (event) => {   //get country
        setCurrentCountry(event.target.value);
        console.log(event.target.value)
    };

    const inputStyles = {
        width:'100%',
        margin: '10px 0px 10px 0px',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayLight,
            },
            '&.Mui-focused fieldset': {
                borderColor: gray,
            },
            underline: {
                '&::placeholder': {
                    color: 'red',
                    opacity: 1,
                }
            },
        },
        '& label.Mui-focused': {
            color: grayLightGhost,
        },
    };

    const boxSX = {
        background: gray,
        width: '20%',
        height: '42px',
        "&:hover": {
            border: "1px solid #000",
            color: '#ffff',
            backgroundColor: grayLight
        },
    };

    return (
        <Box sx={{
            width:'800px'
        }} >
            <CloseIcon sx={{
                position: 'absolute',
                right: '25px',
                top: '25px',
                cursor: 'pointer'
            }}
                onClick={props.handleClose}
            />
            <Typography sx={{ padding: '30px 0px 20px 0px', maxWidth: '100%' }} variant='h4'>{`Данные контрагента + id : ${props.id}`}</Typography>
            <Typography sx={{ fontSize: '24px', fontWeight: '400' ,padding:'0px 0px 10px 0px'}}>{props.name}</Typography>

                    <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>Основная информация</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <FormControl size="small" fullWidth sx={inputStyles}>
                                    <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={"Тип"}
                                        value={currentCompanyType}
                                        onChange={handleChangeCompanyType}
                                    >
                                        {companyData.map((option) => (
                                            <MenuItem key={option.id} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {currentCompanyType === 'Компания' && (
                                < Box sx={{ paddingTop: '10px' }}>
                                    <TextField size="small" sx={inputStyles}
                                        fullWidth
                                        id="nip"
                                        label="NIP"
                                        variant="outlined"
                                        type="text"
                                        value={currentNip}
                                        onChange={handleChangeNip}
                                    />
                                </Box>)
                            }
                           
                        </Box>
                        <Box sx={{ width: '48%' }}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <FormControl size="small" fullWidth sx={inputStyles}>
                                    <InputLabel id="demo-simple-select-label">Происхождение</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"    //countryData
                                        label={"Происхождение"}
                                        value={currentCountryOfCompany}
                                        onChange={handleChangeCountryOfCompany}
                                    >
                                        {countryData.map((option) => (
                                            <MenuItem key={option.id} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="name"
                                    label="Название"
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                        </Box>
                    </Box>
     
                    <Typography sx={{ fontSize: '20px', fontWeight: '500', padding: '30px 0px 0px 0px' }}>Адрес</Typography>
                    <Box sx={boxData}>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="adress"
                                    label="Адрес"
                                    variant="outlined"
                                    type="text"
                                    value={currentAdress}
                                    onChange={handleChangeAdress}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="city"
                                    label="Город"
                                    variant="outlined"
                                    type="text"
                                    value={currentCity}
                                    onChange={handleChangeCity}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="mail"
                                    label="Email"
                                    variant="outlined"
                                    type="text"
                                    value={mail}
                                    onChange={setMailHandler}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', padding: '0px 0px 20px 0px' }}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="mailIndex"
                                    label="Почтовый индекс"
                                    variant="outlined"
                                    type="text"
                                    value={currentMailIndex}
                                    onChange={handleChangeMailIndex}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField sx={inputStyles}
                                size="small"
                                    fullWidth
                                    id="country"
                                    label="Страна"
                                    variant="outlined"
                                    type="text"
                                    value={currentCountry}
                                    onChange={handleChangeCountry}
                                />
                            </Box>
                        </Box>
                    </Box>
             
            <Box sx={{ padding: '20px 0px 0px 0px' }}>
                <Button sx={boxSX} variant="contained">сохранить</Button>
            </Box>
        </Box>
    )

}
export default EditContractor